export enum RequestTypes {
  LOGIN_REQUEST = "login/fetchLoginData",
  SIGN_OUT_REQUEST = "signout/postSignout",
  PUT_CHANGE_PASSWORD = "changePassword/putChangePassword",
  GET_DIDS_REQUEST = "did/fetchDidData",
  GET_CONVERSATIONS_REQUEST = "conversations/fetchConversations",
  GET_CONVERSATIONS_ID_REQUEST = "conversation/fetchConversationData",
  GET_CONTACT_REQUEST = "contacts/fetchContactData",
  GET_NOTES_REQUEST = "notes/fetchNotesData",
  POST_NOTE_REQUEST = "postNotes/postNoteData",
  GET_USER_REQUEST = "user/getUserData",
  PUT_USER_REQUEST = "user/putUserData",
  PUT_NOTE_REQUEST = "postNotes/putNoteData",
  DELETE_NOTE_REQUEST = "postNotes/deleteNoteData",
  GET_SCHEDULE_MSG_REQUEST = "scheduleMessage/fetchscheduleMessageData",
  POST_SCHEDULE_MSG_REQUEST = "postScheduleMessage/postScheduleMessageData",
  DELETE_SCHEDULE_MSG_REQUEST = "postScheduleMessage/deleteScheduleMessageData",
  PUT_SCHEDULE_MSG_REQUEST = "postScheduleMessage/putScheduleMessageData",
  GET_TEMPLATE_REQUEST = "template/fetchTemplateData",
  POST_TEMPLATE_REQUEST = "postTemplate/postTemplateData",
  DELETE_TEMPLATE_REQUEST = "postTemplate/deleteTemplateData",
  PUT_TEMPLATE_REQUEST = "postTemplate/putTemplateData",
  GET_PREFERENCE_REQUEST = "preference/fetchPreferenceData",
  POST_PREFERENCE_REQUEST = "postPreference/postPreferenceData",
  POST_FORGET_PASSWORD = "login/postForgetPassword",
  POST_VERIFY_TOKEN = "verifyToken/fetchVerifyToken",
  POST_RESET_PASSWORD = "resetPassword/fetchResetPassword",
  POST_HOLIDAY_AUTORESPONDER = "autoResponderHoliday/postHolidayResponder",
  GET_HOLIDAY_AUTORESPONDER = "autoResponderHoliday/fetchHolidayResponder",
  DELETE_HOLIDAY_AUTORESPONDER = "autoResponderHoliday/deleteHolidayResponder",
  UPDATE_HOLIDAY_AUTORESPONDER = "autoResponderHoliday/updateHolidayResponder",
  PUT_AUTORESPONDER_REQUEST = "autoresponder/updateAutoResponder",
  GET_AUTORESPONDER_REQUEST = "autoresponder/fetchAutoResponder",
  POST_WORKING_DAYS_REQUEST = "workingDays/postWorkingDays",
  GET_WORKING_DAYS_REQUEST = "workingDays/fetchWorkingDays",
  PUT_WORKING_DAYS_REQUEST = "workingDays/putWorkingDays",
  DELETE_WORKING_DAYS_REQUEST = "workingDays/deleteWorkingDays",
  GET_AUTORESPONDER_KEYWORD_REQUEST = "autoresponderKeyword/fetchAutoresponderKeywordData",
  POST_AUTORESPONDER_KEYWORD_REQUEST = "postAutoresponderKeyword/postAutoresponderKeywordData",
  PUT_AUTORESPONDER_KEYWORD_REQUEST = "postAutoresponderKeyword/putAutoresponderKeywordData",
  DELETE_AUTORESPONDER_KEYWORD_REQUEST = "postAutoresponderKeyword/deleteAutoresponderKeywordData",
  SEND_MESSAGE_REQUEST = "message/PostMessage",
  NEW_CONTACT_REQUEST = "newContact/addNewContact",
  POST_MULTIPLE_MESSAGE_REQUEST = "postMultipleMessage/postMultiPleMessageData",
  IMPORT_CONTACT_REQUEST = "uploadContacts/fetchUploadContacts",
  EXPORT_CONTACT_REQUEST = "uploadContacts/fetchDownloadContacts",
  GET_GROUP_LIST_REQUEST = "groups/fetchGroupsData",
  GET_GROUP_CONTACTS_REQUEST = "groupContacts/fetchGroupContacts",
  ADD_CONTACT_GROUP_REQUEST = "groupContacts/addContactGroup",
  GROUP_MESSAGES_REQUEST = "groupMessages/fetchGroupMessages",
  POST_GROUP_MESSAGE_REUQEST = "groupMessages/postGroupMessage",
  CREATE_GROUP_REQUEST = "createGroup/fetchCreateGroup",
  UPDATE_GROUP_NAME = "createGroup/fetchChangeGroupName",
  DELETE_GROUP_CONTACT = "deleteGroupContact/fetchDeleteGroupContact",
  DELETE_CONTACT_REQUEST = "delete/fetchDeleteContact",
  DELETE_CONVERSATION_REQUEST = "delete/fetchDeleteConversation",
  DELETE_GROUP_REQUEST = "delete/fetchDeleteGroup",
  DELETE_BULK_CONTACTS = "delete/fetchDeleteBulkContact",
  DELETE_BULK_GROUPS = "delete/fetchDeleteBulkGroup",
  DELETE_BULK_CONVERSATION = "delete/fetchDeleteBulkConversation",
  UPLOADFILES_REQUEST = "upload/fetchUploadFiles",
  GET_EVENT_REQUEST = "event/fetchEventData",
  POST_EVENT_REQUEST = "postEvent/postEventData",
  DELETE_EVENT_REQUEST = "postEvent/deleteEventData",
  PUT_EVENT_REQUEST = "postEvent/putEventData",
  DOWNLOAD_GROUP_MESSAGES = "groups/fetchDownloadGroupMessages",
  EXPORT_CONVERSATION_REQUEST = "uploadContacts/fetchDownloadConversation",
  GET_LOGGEDIN_USER_REQUEST = "LoggedInUser/getLoggedInUser",
  POST_BULK_SCHEDULE_MSG_REQUEST = "postScheduleMessage/postBulkScheduleMessage",
  BULK_MESSAGE_CONTACTS = "contactBulk/fetchBulkContacts",
  PLANS_REQUEST = "plan/fetchPlans",
  DID_NOTIFICATION_REQUEST = "didNotification/fetchDidNotification",
  GET_STRIPE_CUSTOMER_REQUEST = "stripeCustomer/fetchStripeCustomer",
  UPDATE_STRIPE_CUSTOMER_BILLING_ADDRESS_REQUEST = "stripeCustomer/updateStripeCustomerBillingAddress",
  CREATE_STRIPE_SUBSCRIPTION_REQUEST = "stripeSubscription/createStripeSubscription",
  SUBSCRIBE_NEW_USER_REQUEST = "stripeSubscription/subscribeNewUser",
  SUBSCRIBE_EXISTING_ACCOUNT_REQUEST = "subscribeExistingAccount/subscribeExistingAccount",
  FETCH_COMPANY_STRIPE_SUBSCRIPTIONS_REQUEST = "stripeSubscription/fetchCompanyStripeSubscriptions",
  FETCH_STRIPE_PAYMENT_METHODS_REQUEST = "stripeSubscription/fetchStripePaymentMethods",
  FETCH_STRIPE_PAYMENT_METHOD_REQUEST = "stripeSubscription/fetchStripePaymentMethod",
  SET_UP_STRIPE_PAYMENT_METHOD_REQUEST = "stripeSubscription/setUpStripePaymentMethod",
  CREATE_STRIPE_PAYMENT_METHOD_REQUEST = "stripeSubscription/createStripePaymentMethod",
  UPDATE_STRIPE_PAYMENT_METHOD_REQUEST = "stripeSubscription/updateStripePaymentMethod",
  CREATE_STRIPE_PRODUCT_DB_REQUEST = "stripeProduct/createStripeProductDb",
  FETCH_STRIPE_PRODUCTS_DB_REQUEST = "stripeProduct/fetchStripeProductsDb",
  FETCH_STRIPE_PRODUCT_DB_REQUEST = "stripeProduct/fetchStripeProductDb",
  FETCH_STRIPE_PRODUCTS_REQUEST = "stripeProduct/fetchStripeProducts",
  FETCH_STRIPE_PRODUCT_REQUEST = "stripeProduct/fetchStripeProduct",
  CREATE_COMPANY = "createCompany/initiateCreateCompany",
  UPDATE_COMPANY = "updateCompany/initiateUpdateCompany",
  FETCH_ROLES = "roles/initiateFetchRoles",
  AVAILABILITY_USERNAME_REQUEST = "availability/fetchAvailablityUsername",
  POST_SEND_OTP = "verifyOtp/postSendOtp",
  POST_VERIFY_OTP = "verifyOtp/postVerifyOtp",
}
