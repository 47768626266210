import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  updateStripeCustomerBillingAddressFailure,
  updateStripeCustomerBillingAddressSuccess
} from "../slices/features/stripeCustomer";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* updateStripeCustomerBillingAddressSaga(action: any) {
  const { payload } = action;
  const { id, data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.updateStripeCustomerBillingAddress,
      baseUrl,
      id,
      data
    );

    yield put(updateStripeCustomerBillingAddressSuccess({ ...response }));

    let paymentMethodData = {};
    if (response.data) {
      paymentMethodData = response.data;
    }

    payload?.cbSuccess && payload?.cbSuccess(paymentMethodData);
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(updateStripeCustomerBillingAddressFailure({ statusCode, statusText }));
  }
}

export function* watchUpdateStripeCustomerBillingAddress() {
  yield takeLatest(
    RequestTypes.UPDATE_STRIPE_CUSTOMER_BILLING_ADDRESS_REQUEST,
    updateStripeCustomerBillingAddressSaga
  );
}
