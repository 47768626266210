import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchStripeProductsDbFailure,
  fetchStripeProductsDbSuccess
} from "../slices/features/stripeProductDb";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchStripeProductsDbSaga(action: any) {
  const { payload } = action;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchStripeProductsDb,
      baseUrl,
    );

    yield put(fetchStripeProductsDbSuccess({ ...response }));

    let productsData = [];
    if (response.data) {
      productsData = response.data;
    }

    payload?.cbSuccess && payload?.cbSuccess(productsData);
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(fetchStripeProductsDbFailure({ statusCode, statusText }));
  }
}

export function* watchFetchStripeProductsDb() {
  yield takeLatest(
    RequestTypes.FETCH_STRIPE_PRODUCTS_DB_REQUEST,
    fetchStripeProductsDbSaga
  );
}
