export const timezoneOptions = ["EST", "UTC"];

export const sortingBy = ["INBOUND", "OUTBOUND"];

export const categoryOptions = ["ORGANIC", "UNITE", "RESELLER"];

export enum CompanyStatus {
  Active = "ACTIVE",
  Pending = "PENDING",
  Inactive = "INACTIVE",
}

export const labelStyle = "original";

export const timezones = {
  "America/Dawson": "Dawson, Yukon",
  "America/Edmonton": "Mountain Time",
  "America/Regina": "Saskatchewan",
  "America/Winnipeg": "Central Time",
  "America/Toronto": "Eastern Time",
  "America/St_Johns": "Newfoundland and Labrador",
};

export const DateFormat = "MMMM d, yyyy h:mm aaaa";
