import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Form, Input, Typography } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import RequestAppAction from "src/store/slices/appActions";
import { PASSWORDREGEX, USERNAMEREGEX } from "src/constants/common";
import CreateNumberForm from "./CreateNumberForm";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface ICreateUserForm {
  form: any;
  n: number;
  name: string;
  email: string;
  numUsers: number;
  numUsersDids: any;
  setNumUsersDids: (num: number) => void;
  numberTypes: any;
  setNumberTypes: (numberType: any) => void;
}

const CreateUserForm = ({
  form,
  n,
  name,
  email,
  numUsers,
  numUsersDids,
  setNumUsersDids,
  numberTypes,
  setNumberTypes,
}: ICreateUserForm) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isExpand, setIsExpand] = useState<boolean>(true);
  const [username, setUsername] = useState<string>("");
  const [billingEmail, setBillingEmail] = useState<boolean>(true);
  const [numDids, setNumDids] = useState<number>(1);

  const userRequiredFields = {
    username: true,
    password: true,
    contactName: true,
    email: true,
  };

  useEffect(() => {
    if (!username) return;

    const debounce = setTimeout(() => {
      dispatch(
        RequestAppAction.availabilityUsername({
          username: username,
          cbSuccess: (res: any) => {
            if (res?.statusCode === 200) {
              form.setFields([
                {
                  name: `user_${n}_username`,
                  errors: "",
                },
              ]);
            }
          },
          cbFailure: (e: string) => {
            form.setFields([
              {
                name: `user_${n}_username`,
                errors: [`${e}`],
              },
            ]);
          },
        })
      );
    }, 800);

    return () => {
      clearTimeout(debounce);
    };
  }, [username]);

  useEffect(() => {
    setNumDids(numUsersDids[n] || 1);
  }, [numUsers]);

  const validateUserName = () => {
    const username = form.getFieldValue(`user_${n}_username`);
    const trimmedValue = username ? username.trim() : "";

    if (!trimmedValue || trimmedValue.length === 0) {
      return Promise.resolve();
    }

    if (trimmedValue.length < 6 || trimmedValue.length > 20) {
      return Promise.reject(new Error(t("fields.usernameLength")));
    }

    if (!USERNAMEREGEX.test(trimmedValue)) {
      return Promise.reject(new Error(t("fields.usernameRestriction")));
    }

    for (let i = 1; i <= numUsers; i++) {
      if (i !== n) {
        const value = form.getFieldValue(`user_${i}_username`);
        if (value === trimmedValue) {
          return Promise.reject(new Error(
            t("fields.usernameAlreadyInUse", { number: i })
          ));
        }
      }
    }

    return Promise.resolve();
  }

  const validatePassword = () => {
    const password = form.getFieldValue(`user_${n}_password`);

    if (!password || password.length === 0) {
      return Promise.resolve();
    }

    if (!PASSWORDREGEX.test(password)) {
      return Promise.reject(new Error(t("message.passwordRegexError")));
    }

    return Promise.resolve();
  }

  const validateConfirmPassword = () => {
    const password = form.getFieldValue(`user_${n}_password`);
    const confirmPass = form.getFieldValue(`user_${n}_confirmPassword`);

    if (!confirmPass || confirmPass.length === 0) {
      return Promise.resolve();
    }

    if (password !== confirmPass) {
      return Promise.reject(new Error(t("fields.passwordDoNotMatchError")));
    }

    return Promise.resolve();
  }

  const addNumber = () => {
    setNumDids(numDids + 1);
    const newNumUsersDids = numUsersDids;
    newNumUsersDids[n] = numDids + 1;
    setNumUsersDids(newNumUsersDids);
  };

  const deleteNumber = (j: number) => {
    const allValues = form.getFieldsValue(true);

    for (let i = j; i < numDids; i++) {
      const currKeys = Object.keys(allValues)
        .filter(key => key.startsWith(`user_${n}_did_${i}_`));

      const nextValues = currKeys.reduce((obj: { [key: string]: any }, key: string) => {
        const nextKey = key.replace(`user_${n}_did_${i}_`, `user_${n}_did_${i + 1}_`);
        const nextValue = form.getFieldValue(nextKey); 
        const newKey = key;
        obj[newKey] = nextValue;
        return obj;
      }, {});

      form.setFieldsValue(nextValues);
    }

    // Clear the last set of fields
    const lastNumKeys = Object.keys(allValues)
      .filter(key => key.startsWith(`user_${n}_did_${numDids}_`));
    const clearLastFields = lastNumKeys.reduce((obj: { [key: string]: any }, key: string) => {
      obj[key] = undefined;
      return obj;
    }, {});
    form.setFieldsValue(clearLastFields);

    setNumDids(numDids - 1);

    const newNumUsersDids = numUsersDids;
    newNumUsersDids[n] = numDids - 1;
    setNumUsersDids(newNumUsersDids);
    // form.validateFields();
  };

  return (
    <div
      className={`${styles.sign_up_form_item} ${
        styles.sign_up_form
      } ${styles.sign_up_form_accordion_section}`}
    >
      <div
        style={{ position: "relative" }}
        className={styles.sign_up_form_item}
      >
        <Text className={styles.sign_up_form_section_title}>
          {t("subscribeStep3.userInfo", { number: n })}
        </Text>
        <Button
          className={styles.sign_up_form_accordion_btn}
          icon={
            isExpand ? ( <UpOutlined /> ) : ( <DownOutlined /> )
          }
          onClick={() => setIsExpand(!isExpand)}
        >
        </Button>
      </div>
      <Form.Item
        name={`user_${n}_username`}
        label={t("fields.createUsername")}
        rules={[
          {
            required: userRequiredFields.username,
            message: t("fields.usernameRequired")
          },
          { validator: validateUserName },
        ]}
        className={
          `${styles.sign_up_form_item} ${
            userRequiredFields.username ? styles.required : ""
          } ${isExpand ? "" : styles.hidden}`
        }
      >
        <Input
          type="text"
          placeholder={t("fields.createUsername")}
          className={styles.sign_up_form_input}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        name={`user_${n}_password`}
        label={t("fields.createPassword")}
        rules={[
          {
            required: userRequiredFields.password,
            message: t("fields.passwordRequired")
          },
          { validator: validatePassword },
        ]}
        className={
          `${styles.sign_up_form_item} ${
            userRequiredFields.username ? styles.required : ""
          } ${isExpand ? "" : styles.hidden}`
        }
      >
        <Input.Password
          placeholder={t("fields.createPassword")}
          className={styles.sign_up_form_input}
        />
      </Form.Item>
      <Form.Item
        name={`user_${n}_confirmPassword`}
        label={t("fields.confirmPassword")}
        rules={[
          {
            required: userRequiredFields.password,
            message: t("fields.confirmPasswordRequired")
          },
          { validator: validateConfirmPassword },
        ]}
        className={
          `${styles.sign_up_form_item} ${
            userRequiredFields.username ? styles.required : ""
          } ${isExpand ? "" : styles.hidden}`
        }
      >
        <Input.Password
          placeholder={t("fields.confirmPassword")}
          className={styles.sign_up_form_input}
        />
      </Form.Item>
      <Form.Item
        name={`user_${n}_contactName`}
        label={t("fields.fullContactName")}
        initialValue={n === 1 && name ? name : ""}
        rules={[
          {
            required: userRequiredFields.contactName,
            message: t("fields.fullContactNameRequired")
          },
        ]}
        className={
          `${styles.sign_up_form_item} ${
            userRequiredFields.contactName ? styles.required : ""
          } ${isExpand ? "" : styles.hidden}`
        }
      >
        <Input
          placeholder={t("fields.fullContactName")}
          className={styles.sign_up_form_input}
        />
      </Form.Item>
      <Form.Item
        name={`user_${n}_email`}
        initialValue={n === 1 ? email : ""}
        label={t("fields.emailAddress")}
        rules={[
          {
            required: userRequiredFields.email,
            message: t("fields.emailRequired")
          },
          { type: "email", message: t("fields.emailError") },
        ]}
        className={
          `${styles.sign_up_form_item} ${
            userRequiredFields.email ? styles.required : ""
          } ${isExpand ? "" : styles.hidden}`
        }
      >
        <Input
          placeholder={t("fields.emailAddress")}
          className={styles.sign_up_form_input}
        />
      </Form.Item>
      {n === 1 && (
        <div className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}>
          <div className={styles.checkboxes}>
            <Checkbox
              checked={billingEmail}
              onChange={() => {
                if (billingEmail) {
                  form.resetFields(["billingEmailAddress"]);
                }
                setBillingEmail(!billingEmail);
              }}
            >
              {t("fields.useThisEmailAsBillingEmailAddress")}
            </Checkbox>
          </div>
        </div>
      )}
      {n === 1 && !billingEmail && (
        <Form.Item
          name="billingEmailAddress"
          initialValue={email}
          label={t("fields.billingEmailAddress")}
          rules={[
            {
              required: n === 1 && !billingEmail && userRequiredFields.email,
              message: t("fields.billingEmailRequired")
            },
            { type: "email", message: t("fields.emailError") },
          ]}
          className={
            `${styles.sign_up_form_item} ${
              userRequiredFields.email ? styles.required : ""
            } ${isExpand ? "" : styles.hidden}`
          }
        >
          <Input
            placeholder={t("fields.emailAddress")}
            className={styles.sign_up_form_input}
          />
        </Form.Item>
      )}

      {[...Array(numDids)].map((_, j: number) => (
        <React.Fragment key={`user-${n}-did-${j + 1}`}>
          {numDids > 1 && (
            <div
              style={{ justifyContent: "flex-end" }}
              className={`${styles.inline_btns} ${isExpand ? "" : styles.hidden}`}
            >
              <span className={styles.inline_btn} onClick={() => deleteNumber(j + 1)}>
                {"- "}{t("subscribeStep3.deleteNumber", { number: j + 1 })}
              </span>
            </div>
          )}
          <CreateNumberForm
            form={form}
            i={n}
            j={j + 1}
            isUserExpand={isExpand}
            numDids={numDids}
            numberTypes={numberTypes}
            setNumberTypes={setNumberTypes}
          />
        </React.Fragment>
      ))}
      <div className={`${styles.inline_btns} ${isExpand ? "" : styles.hidden}`}>
        <span className={styles.inline_btn} onClick={() => addNumber()}>
          {"+ "}{t("subscribeStep3.addAnotherNumber")}
        </span>
      </div>
    </div>
  );
};

export default CreateUserForm;
