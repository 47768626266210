import { useEffect, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { ILocation } from "src/constants/types";

const useGeoLocation = () => {
  const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? "";

  const [error, setError] = useState('');
  const [location, setLocation] = useState<ILocation>({});

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY
  });

  const onSuccess = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: latitude,
      lng: longitude,
    };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const countryData = results.find((result) => result.types.includes('country'));
        if (countryData?.address_components) {
          setLocation({
            latitude: latitude,
            longitude: longitude,
            country: countryData.address_components[0].long_name,
            countryCode: countryData.address_components[0].short_name,
          });
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  };

  const onError = (error: GeolocationPositionError) => {
    setError(error.message);
  };

  useEffect(() => {
    if (location.latitude && location.longitude) return;
    if (!isLoaded) return;

    const { geolocation } = navigator;

    if (!geolocation) {
      setError("Geolocation is not supported by this browser.");
      return;
    }

    geolocation.getCurrentPosition(onSuccess, onError, {});
  }, [isLoaded]);

  return { data: location, error };
};

export default useGeoLocation;
