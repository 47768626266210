import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { fetchAvailablityUsernameFailure, fetchAvailablityUsernameSuccess } from "../slices/features/availability";
import { RequestTypes } from "../types";

const appService = new AppService();

function* availabilityUsernameSaga(action: any) {
  const { payload } = action;
  const { username } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.availabilityUsername,
      baseUrl,
      username
    );

    payload?.cbSuccess && payload?.cbSuccess({ ...response });

    yield put(fetchAvailablityUsernameSuccess({ ...response }));
  } catch (errors: any) {
    const { statusCode, statusText } = errors;
    const message = errors?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchAvailablityUsernameFailure({ statusCode, statusText }));
  }
}

export function* watchAvailabilityUsername() {
  yield takeLatest(
    RequestTypes.AVAILABILITY_USERNAME_REQUEST,
    availabilityUsernameSaga
  );
}
