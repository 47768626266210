import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Flex, notification, Space } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import RequestAppAction from "src/store/slices/appActions";
import { initiateCreateCompany } from "src/store/slices/features/createCompany";
import { getCreateCompanyData, getCreateCompanyStates } from "src/store/selectors/features/createCompany";
import { IStripeProducts } from "src/constants/types";
import { formatAddress } from "src/utils/format";
import { countryCodeToCurrency, Status } from "src/constants/common";
import Plans from "src/components/subscribe/steps/Plans";
import Step1 from "src/components/subscribe/steps/Step1";
import Step2 from "src/components/subscribe/steps/Step2";
import Step3 from "src/components/subscribe/steps/Step3";
import Step5 from "src/components/subscribe/steps/Step5";
import styles from "./subscribe.module.scss";

const Subscribe: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const createCompanyState: any = useSelector(getCreateCompanyStates);
  const createCompanyData: any = useSelector(getCreateCompanyData);

  const [selectedPlan, setSelectedPlan] = useState<IStripeProducts | null | undefined>(null);
  const [subscriptionStep, setSubscriptionStep] = useState<number>(0);
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
  const [address, setAddress] = useState<any>(null);
  const [numUsersDids, setNumUsersDids] = useState<any>({ 1: 1 });
  const [signUpUserFormData, setSignUpUserFormData] = useState<any>(null);
  const [signUpUserData, setSignUpUserData] = useState<any>({});
  const [additional, setAdditional] = useState<any>({ numUser: 0, numDid: 0 });
  const [numberTypes, setNumberTypes] = useState<any>({});
  const [paymentIntentSecret, setPaymentIntentSecret] = useState<string>("");
  const [stripe, setStripe] = useState<any>(null);
  const [cardElement, setCardElement] = useState<null | JSX.Element>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY ?? "");

  // useEffect(() => {
  //   console.log(createCompanyState);
  // }, [createCompanyState]);

  useEffect(() => {
    if (subscriptionStep < 5) {
      return;
    }
    if (createCompanyData?.data) {
      const data = createCompanyData.data;
      if (data.id) {
        onPaymentMethod(+data.id);
      }
    }
  }, [createCompanyData]);

  const prevStep = () => {
    setSubscriptionStep(subscriptionStep - 1);
  }
  
  const nextStep = () => {
    setSubscriptionStep(subscriptionStep + 1);
  }

  const sendOtp = (email?: string, func?: any) => {
    const sendEmail = email ?? signUpUserData.email;
    if (!sendEmail) {
      notification.error({ message: t("fields.emailRequired") });
      return;
    }

    dispatch(RequestAppAction.sendOtp({
      data: {
        email: sendEmail,
        firstName: signUpUserData.firstName,
        lastName: signUpUserData.lastName,
      },
      cbSuccess: (res: any) => {
        const successMessage = res?.data?.message ?? t("notification.otpEmailSent");
        notification.success({ message: successMessage });
        if (func) func();
      },
      cbFailure: (e: string) => {
        notification.error({ message: e });
        if (func) func();
      },
    }));
  };

  const onStage1 = (values: any) => {
    const { email, firstName, lastName } = values;
    const data = {
      selectedPlan,
      email,
      firstName,
      lastName,
    };
    setSignUpUserData(data);

    sendOtp(email, nextStep);
  };

  const onStage2 = (values: any, form: any) => {
    const { otp } = values;

    dispatch(RequestAppAction.verifyOtp({
      data: {
        email: signUpUserData.email,
        otp: otp
      },
      cbSuccess: (res: any) => {
        const successMessage = res?.data?.message ?? t("notification.otpVerified");
        notification.success({ message: successMessage });
        nextStep();
      },
      cbFailure: (e: string) => {
        notification.error({ message: e });
        form.setFields([
          { name: "otp", errors: [e ?? t("fields.enterOtpError")] }
        ]);
      },
    }));
  };

  const onStage3 = (values: any) => {
    const {
      companyName,
      timezone,
      country,
      province,
      state,
      city,
      postalCode,
      billingEmailAddress,
      howDidYouHear
    } = values;

    setSignUpUserFormData(values);

    const numUsers = Object.keys(values).filter((key) =>
      key.startsWith("user_") && key.endsWith("_username")
    ).length;

    const address = formatAddress(
      postalCode,
      country ?? selectedCountry,
      province,
      state,
      city,
    );
    setAddress(address);

    const payloadData: any = {
      stripeProductId: signUpUserData?.selectedPlan?.id ?? null,
      status: Status.ACTIVE,
      name: companyName,
      timeZone: timezone,
      ...address,
      currency: address?.country ? countryCodeToCurrency[address.country] : "CAD",
      users: [],
      billingEmailAddress: billingEmailAddress ?? "",
      notes: howDidYouHear,
      billedExternally: false,
    };

    let totalNumDids = 0;
    
    for (let i = 1; i <= numUsers; i++) {
      if (i === 1) {
        if (!billingEmailAddress || billingEmailAddress === "") {
          payloadData.billingEmailAddress = values[`user_${i}_email`];
        }
      }

      const newNumberRequests = Object.keys(values).filter((key) =>
        key.startsWith(`user_${i}_did_`) && key.endsWith("_addNumber")
      ).length;

      const user: any = {
        name: values[`user_${i}_contactName`],
        status: Status.ACTIVE,
        email: values[`user_${i}_email`],
        username: values[`user_${i}_username`],
        password: values[`user_${i}_password`],
        isRequestForNewNumber: newNumberRequests > 0 ? true : false,
        newNumberRequests: newNumberRequests,
        roleId: 2,
        dids: [],
      };

      const numDids = Object.keys(values).filter((key) =>
        key.startsWith(`user_${i}_did_`)
        && (key.endsWith("_addNumber") || key.endsWith("_specialRequests"))
      ).length;
      totalNumDids += numDids;
      for (let j = 1; j <= numDids; j++) {
        const number = values[`user_${i}_did_${j}_addNumber`];
        let numberType = "";
        if (numberTypes) {
          numberType = numberTypes[`user_${i}_did_${j}_numberType`] ?? "";
        }
        const specialRequests = values[`user_${i}_did_${j}_specialRequests`];
        const did: any = {
          did: number ?? "",
          status: !!number ? Status.PENDING : Status.INACTIVE,
          email: values[`user_${i}_email`],
          specialRequests: numberType + `${specialRequests ? ", " + specialRequests : ""}`,
        };
        user.dids.push(did);
      }
      payloadData.users.push(user);
    }

    setSignUpUserData(payloadData);
    setAdditional({ numUser: numUsers - 1, numDid: totalNumDids - 1 });

    nextStep();
  };

  const onStage4 = () => {
    nextStep();
  };

  const onStage5 = () => {
    setDisableButton(true);

    // Create account
    if (!createCompanyState?.isLoading) {
      dispatch(initiateCreateCompany({
        data: signUpUserData,
        cbSuccess: () => {
          nextStep();
        },
        cbFailure: (e: string) => {
          notification.error({ message: e + " Contact admin for the assistance." });
          nextStep();
        },
      }));
    }
  };

  const onPaymentMethod = (accountId: number) => {
    if (!address || !stripe || !cardElement || !paymentIntentSecret) {
      return;
    }
    stripe.confirmCardSetup(
      paymentIntentSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            address: {
              country: address.country,
              state: address.state,
              city: address.city,
              line1: address.line1,
              line2: address.line2,
              postal_code: address.postalCode,
            },
            email: signUpUserData.email,
            name: signUpUserData.companyName,
          }
        }
      }
    ).then((result: any) => {
      if (result.error) {
        notification.error({ message: result.error });
        nextStep();
      } else {
        dispatch(RequestAppAction.createStripePaymentMethod({
          id: accountId,
          paymentMethodId: result.setupIntent.payment_method,
          cbSuccess: () => {
            dispatch(RequestAppAction.subscribeExistingAccount({
              data: {
                companyId: accountId,
                ...signUpUserData
              },
              cbSuccess: () => {
                nextStep();
                setDisableButton(false);
              },
              cbFailure: (e: string) => {
                notification.error({ message: e });
                nextStep();
                setDisableButton(false);
              },
            }));
          },
          cbFailure: (e: string) => {
            notification.error({ message: e });
            nextStep();
          },
        }));
      }
    });
  };

  return (
    <Flex
      justify="start"
      align="center"
      gap={10}
      vertical
      style={{ width: "100%", overflowY: "auto" }}
      className={styles.subscribe_form_container}
    >
      <Space
        direction="vertical"
        style={{ width: "100%", maxWidth: "1440px" }}
      >
        {subscriptionStep === 0 ? (
          <Plans setSelectedPlan={setSelectedPlan} nextStep={nextStep}  />
        ) : subscriptionStep === 1 ? (
          <Step1 onFinish={onStage1} />
        ) : subscriptionStep === 2 ? (
          <Step2
            email={signUpUserData.email}
            sendOtp={sendOtp}
            onFinish={onStage2}
          />
        ) : subscriptionStep === 3 ? (
          <Step3
            userData={signUpUserData}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            numberTypes={numberTypes}
            setNumberTypes={setNumberTypes}
            signUpUserFormData={signUpUserFormData}
            numUsersDids={numUsersDids}
            setNumUsersDids={setNumUsersDids}
            onFinish={onStage3}
          />
        ) : (
          <Elements stripe={stripePromise}>
            <Step5
              prevStep={prevStep}
              subscriptionStep={subscriptionStep}
              setSubscriptionStep={setSubscriptionStep}
              setPaymentIntentSecret={setPaymentIntentSecret}
              setStripe={setStripe}
              setCardElement={setCardElement}
              userData={signUpUserData}
              selectedPlan={selectedPlan}
              additional={additional}
              disableButton={disableButton}
              onStage4={onStage4}
              onFinish={onStage5}
            />
          </Elements>
        )}
      </Space>

      {/* {selectedPlan && (
        <Text>Selected Plan: {selectedPlan.stripeProductName}</Text>
      )} */}
    </Flex>
  );
};

export default Subscribe;
