import { Button, Col, Row, Typography } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertCurrency } from "src/utils/format";
import { InvoiceStatus } from "src/constants/common";
import styles from "./invoice.module.scss";

const { Text } = Typography;

interface IInvoiceItem {
  invoiceData: any;
  currency: string;
}

const InvoiceItem = ({ invoiceData, currency }: IInvoiceItem) => {
  const { t } = useTranslation();

  const [expand, setExpand] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [invoiceFileUrl, setInvoiceFileUrl] = useState<string>("");
  const [paymentUrl, setPaymenturl] = useState<string>("");
  const [amountPaid, setAmountPaid] = useState<string>("0.00");
  const [amountUnpaid, setAmountUnpaid] = useState<string>("0.00");
  const [amountTotal, setAmountTotal] = useState<string>("0.00");
  const [status, setStatus] = useState<string>("");

  useEffect(() => {
    if (!invoiceData) {
      return;
    }

    const periodStart = invoiceData.periodStart
      ? new Date(invoiceData.periodStart).toLocaleDateString()
      : "";
      setStartDate(periodStart);

    const periodEnd = invoiceData.periodEnd
      ? new Date(invoiceData.periodEnd).toLocaleDateString()
      : "";
    setEndDate(periodEnd);

    const pdfLink = invoiceData.pdfLink ?? "";
    setInvoiceFileUrl(pdfLink);

    // Payment link expires after 30 days from due date
    if (!!invoiceData.dueDate || !!invoiceData.periodEnd) {
      const dueDate = new Date(invoiceData.dueDate ?? invoiceData.periodEnd);
      const currentDate = new Date();
      const diffTime = Math.abs(dueDate.getTime() - currentDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 30) {
        setPaymenturl(invoiceData.paymentLink ?? "");
      }
    }

    const total = invoiceData?.amount ? invoiceData.amount / 100 : 0;
    const paid = invoiceData?.amountPaid ? invoiceData.amountPaid / 100 : 0;
    const unpaid = total - paid;
    if (currency !== 'CAD') {
      convertCurrency(total, 'CAD', 'USD').then((converted: any) => {
        if (converted) {
          setAmountTotal(converted);
        }
      });
      convertCurrency(paid, 'CAD', 'USD').then((converted: any) => {
        if (converted) {
          setAmountPaid(converted);
        }
      });
      convertCurrency(unpaid, 'CAD', 'USD').then((converted: any) => {
        if (converted) {
          setAmountUnpaid(converted);
        }
      });
    } else {
      setAmountTotal(total.toFixed(2));
      setAmountPaid(paid.toFixed(2));
      setAmountUnpaid(unpaid.toFixed(2));
    }
  }, [invoiceData]);

  useEffect(() => {
    if (amountPaid && amountTotal) {
      setStatus(
        amountPaid === amountTotal
        ? InvoiceStatus.PAID
        : amountUnpaid === amountTotal
        ? InvoiceStatus.NOTPAID
        : InvoiceStatus.PARTIAL
      );
    }
  }, [amountPaid, amountTotal]);

  return (
    <Row
      gutter={[16, 0]}
      className={styles.invoice_container}
    >
      {invoiceData ? (
        <>
          <Row
            gutter={[16, 8]}
            className={styles.invoice_summary}
          >
            <Col className={styles.invoice_summary_col}>
              <div style={{ textAlign: "left" }}>
                <Text className={styles.invoice_summary_title}>
                  INVOICE: {startDate}-{endDate}
                </Text>
              </div>
              {status && (
                <div
                  className={`${styles.invoice_summary_status} ${
                    status === InvoiceStatus.PAID
                      ? styles.paid
                      : status === InvoiceStatus.PARTIAL
                      ? styles.partial
                      : styles.not_paid
                  }`}
                >
                  {status}
                </div>
              )}
            </Col>
            <Col
              className={styles.invoice_summary_col}
              style={{ justifyContent: "flex-end", paddingLeft: 0 }}
            >
              <Button
                className="white_btn"
                icon={
                  expand ? (
                    <ArrowUpOutlined style={{ fontSize: 10 }} />
                  ) : (
                    <ArrowDownOutlined style={{ fontSize: 10 }} />
                  )
                }
                onClick={() => setExpand(!expand)}
              >
                See More
              </Button>
            </Col>
          </Row>
          {expand && (
            <Row
              gutter={[16, 16]}
              className={styles.invoice_detail}
            >
              <Col span={5} className={styles.invoice_detail_col}>
                <Text className={styles.invoice_detail_label}>
                  Invoice Period
                </Text>
                <Text className={styles.invoice_detail_data}>
                  {startDate}-{endDate}
                </Text>
              </Col>
              <Col span={4} className={styles.invoice_detail_col}>
                <Text className={styles.invoice_detail_label}>
                  Payment Status
                </Text>
                <Text className={styles.invoice_detail_data}>
                  {status}
                </Text>
              </Col>
              <Col span={4} className={styles.invoice_detail_col}>
                <Text className={styles.invoice_detail_label}>
                  Paid Amount
                </Text>
                <Text className={styles.invoice_detail_data}>
                  {amountPaid}
                </Text>
              </Col>
              <Col span={4} className={styles.invoice_detail_col}>
                <Text className={styles.invoice_detail_label}>
                  Unpaid Amount
                </Text>
                <Text className={styles.invoice_detail_data}>
                  {amountUnpaid}
                </Text>
              </Col>
              <Col span={4} className={styles.invoice_detail_col}>
                <Text className={styles.invoice_detail_label}>
                  Total Amount
                </Text>
                <Text className={styles.invoice_detail_data}>
                  {amountTotal}
                </Text>
              </Col>
              <Col span={24} className={styles.invoice_detail_col}>
                <Text className={styles.invoice_detail_label}>
                  Invoice PDF Link
                </Text>
                <Text className={styles.invoice_detail_data}>
                  <a href={invoiceFileUrl} target="_blank">{invoiceFileUrl}</a>
                </Text>
              </Col>
              {paymentUrl && (
                <Col span={24} className={styles.invoice_detail_col}>
                  <Text className={styles.invoice_detail_label}>
                    Payment Link
                  </Text>
                  <Text className={styles.invoice_detail_data}>
                    <a href={paymentUrl} target="_blank">{paymentUrl}</a>
                  </Text>
                </Col>
              )}
            </Row>
          )}
        </>
      ) : (
        <Text>{t("plan.noInvoiceAvailable")}</Text>
      )}
    </Row>
  );
};

export default InvoiceItem;
