import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { fetchStripeCustomerFailure, fetchStripeCustomerSuccess } from "../slices/features/stripeCustomer";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchStripeCustomerSaga(action: any) {
  const { payload } = action;
  const { id } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchStripeCustomer,
      baseUrl,
      id
    );

    yield put(fetchStripeCustomerSuccess({ ...response }));

    let data = {};
    if (response.data) {
      data = response.data;
    }

    payload?.cbSuccess && payload?.cbSuccess(data);
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(fetchStripeCustomerFailure({ statusCode, statusText }));
  }
}

export function* watchFetchStripeCustomer() {
  yield takeLatest(RequestTypes.GET_STRIPE_CUSTOMER_REQUEST, fetchStripeCustomerSaga);
}
