import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Flex, Form, Input } from "antd";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import Header from "../Header";
import styles from "src/pages/subscribe/subscribe.module.scss";

interface IStep1 {
  onFinish: (values: any) => void;
}

const Step1 = ({ onFinish }: IStep1) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const requiredFields = {
    email: true,
    firstName: true,
    lastName: true,
  };

  return (
    <Flex
      vertical
      gap={25}
      justify="center"
      align="center"
      style={{ width: "100%", height: "100%" }}
      className={styles.sign_up_container}
    >
      <Header step={1} />
      <div className={styles.sign_up_form_container}>
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          autoComplete="off"
          className={styles.sign_up_form}
        >
          <Form.Item
            name="email"
            label={t("fields.emailAddress")}
            rules={[
              {
                required: requiredFields.email,
                message: t("fields.emailRequired")
              },
              { type: "email", message: t("fields.emailError") },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.email ? styles.required : ""}`
            }
          >
            <Input
              prefix={<MailOutlined className={styles.site_form_item_icon} />}
              placeholder={t("fields.emailAddress")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>
          <Form.Item
            name="firstName"
            label={t("fields.firstName")}
            rules={[
              {
                required: requiredFields.firstName,
                message: t("fields.firstNameRequired")
              },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.firstName ? styles.required : ""}`
            }
          >
            <Input
              prefix={<UserOutlined className={styles.site_form_item_icon} />}
              type="text"
              placeholder={t("fields.firstName")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={t("fields.lastName")}
            rules={[
              {
                required: requiredFields.lastName,
                message: t("fields.lastNameRequired")
              },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.lastName ? styles.required : ""}`
            }
          >
            <Input
              prefix={<UserOutlined className={styles.site_form_item_icon} />}
              type="text"
              placeholder={t("fields.lastName")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>

          {/* TODO: RECAPTCHA */}

          <Form.Item className={`${styles.sign_up_form_item} ${styles.sign_up_form_btn}`}>
            <Button htmlType="submit" className={styles.primary}>
              {t("button.register")}
            </Button>
          </Form.Item>

          <div className={styles.sign_up_form_note}>
            <b>
              {t("subscribeStep1.alreadyHaveAnAccount")}
              {" "}
              <span
                className={styles.inline_btn}
                onClick={() => navigate("/login")}
              >
                {t("subscribeStep1.signIn")}
              </span>
            </b>
          </div>
        </Form>
      </div>
    </Flex>
  );
};

export default Step1;
