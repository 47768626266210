import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  stripeSubscriptionState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  postApiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const stripeSubscriptionSlice = createSlice({
  name: "stripeSubscription",
  initialState: INITIAL_STATE,
  reducers: {
    createStripeSubscription: (state) => {
      state.stripeSubscriptionState.isLoading = true;
    },
    createStripeSubscriptionSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.stripeSubscriptionState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    createStripeSubscriptionFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.stripeSubscriptionState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    subscribeNewUser: (state) => {
      state.stripeSubscriptionState.isLoading = true;
    },
    subscribeNewUserSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.stripeSubscriptionState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    subscribeNewUserFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.stripeSubscriptionState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchStripeSubscription: (state) => {
      state.stripeSubscriptionState.isLoading = true;
    },
    fetchStripeSubscriptionSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.stripeSubscriptionState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchStripeSubscriptionFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.stripeSubscriptionState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchCompanyStripeSubscriptions: (state) => {
      state.stripeSubscriptionState.isLoading = true;
    },
    fetchCompanyStripeSubscriptionsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.stripeSubscriptionState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchCompanyStripeSubscriptionsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.stripeSubscriptionState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearStripeSubscription: (state) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.stripeSubscriptionState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: null,
      },
    }),
  },
});

export const {
  createStripeSubscription,
  createStripeSubscriptionFailure,
  createStripeSubscriptionSuccess,
  subscribeNewUser,
  subscribeNewUserFailure,
  subscribeNewUserSuccess,
  fetchStripeSubscription,
  fetchStripeSubscriptionFailure,
  fetchStripeSubscriptionSuccess,
  fetchCompanyStripeSubscriptions,
  fetchCompanyStripeSubscriptionsFailure,
  fetchCompanyStripeSubscriptionsSuccess,
  clearStripeSubscription,
} = stripeSubscriptionSlice.actions;
export const stripeSubscriptionReducer = stripeSubscriptionSlice.reducer;
