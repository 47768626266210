import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import Header from "../Header";

const { Text } = Typography;

const Step6 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header step={6} />
      <Text
        style={{
          width: "100%",
          textAlign: "center",
        }}
        className="font-size-20 font-weight-600"
      >
        {t("subscribeStep6.note")}
      </Text>
    </>
  );
};

export default Step6;
