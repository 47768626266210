import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, notification, Select, SelectProps } from "antd";
import { useForm } from "antd/es/form/Form";
import { Stripe, StripeCardNumberElement } from "@stripe/stripe-js";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import RequestAppAction from "src/store/slices/appActions";
import { getOptionsList } from "src/utils/helper-functions";
import { CAPOSTALCODEREGEX, provinces, selectFilterOption, states, USPOSTALCODEREGEX } from "src/constants/common";
import styles from "src/pages/subscribe/subscribe.module.scss";

interface IPaymentMethodForm {
  companyData: any;
  selectedCountry: string | undefined;
  setSelectedCountry: (country: string | undefined) => void;
  setPaymentIntentSecret: (paymentIntentSecret: string) => void;
  onFinish: (
    values: any,
    stripe: Stripe | null,
    cardElement: StripeCardNumberElement | null
  ) => void;
}

const PaymentMethodForm = ({
  companyData,
  selectedCountry,
  setSelectedCountry,
  setPaymentIntentSecret,
  onFinish,
}: IPaymentMethodForm) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [form] = useForm();

  const [cardNumberCompleted, setCardNumberCompleted] = useState<boolean>(false);
  const [expDateCompleted, setExpDateCompleted] = useState<boolean>(false);
  const [cvcCompleted, setCvcCompleted] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const requiredFields = {
    country: true,
    province: true,
    state: true,
    city: true,
    postalCode: true,
    cardNumber: true,
    expirationDate: true,
    cvc: true,
  };

  const countryOptions: SelectProps['options'] = [
    { key: "CA", label: t("geoLocation.countries.CA"), value: "CA" },
    { key: "US",label: t("geoLocation.countries.US"), value: "US" },
  ];
  const provinceOptions: SelectProps['options'] = getOptionsList(provinces);
  const stateOptions: SelectProps['options'] = getOptionsList(states);

  useEffect(() => {
    onPaymentIntent();
  }, []);

  useEffect(() => {
    if (companyData) {
      form.setFieldsValue({
        ["country"]: selectedCountry ? countryOptions.find(c => c.value === selectedCountry) : null,
        ["province"]: companyData?.state && selectedCountry === "CA" ? provinceOptions.find(p => p.value === companyData.state) : null,
        ["state"]: companyData?.state && selectedCountry === "US" ? stateOptions.find(p => p.value === companyData.state) : null,
        ["city"]: companyData?.city || "",
        ["postalCode"]: companyData?.postalCode || "",
      })
    }
  }, [companyData]);

  useEffect(() => {
    if (cardNumberCompleted && expDateCompleted && cvcCompleted) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [cardNumberCompleted, expDateCompleted, cvcCompleted]);

  const onChangeCountry = (value: string) => {
    setSelectedCountry(value);
    if (value === "CA") {
      form.resetFields(["state"]);
    } else if (value === "US") {
      form.resetFields(["province"]);
    }
  };

  const validatePostalCode = () => {
    const postalCode = form.getFieldValue("postalCode");
    const trimmedValue = postalCode ? postalCode.trim() : "";

    if (!trimmedValue || trimmedValue.length === 0) {
      return Promise.resolve();
    }

    if (selectedCountry === "CA") {
      if (!CAPOSTALCODEREGEX.test(trimmedValue)) {
        return Promise.reject(new Error(t("fields.postalCodeInvalid")));
      }
    }

    if (selectedCountry === "US") {
      if (!USPOSTALCODEREGEX.test(trimmedValue)) {
        return Promise.reject(new Error(t("fields.zipCodeInvalid")));
      }
    }

    return Promise.resolve();
  };

  const onPaymentIntent = () => {
    dispatch(RequestAppAction.setUpStripePaymentMethod({
      data: {},
      cbSuccess: (res: any) => {
        console.log(res);
        setPaymentIntentSecret(res.client_secret);
      },
      cbFailure: (e: string) => {
        notification.error({ message: e });
      },
    }));
  };

  const onCardNumberChange = (event: any) => {
    // Check if the form is valid
    if (event?.complete && event.complete === true && !event.error) {
      setCardNumberCompleted(true);
    } else {
      setCardNumberCompleted(false);
    }
  };

  const onExpDateChange = (event: any) => {
    // Check if the form is valid
    if (event?.complete && event.complete === true && !event.error) {
      setExpDateCompleted(true);
    } else {
      setExpDateCompleted(false);
    }
  };

  const onCvcChange = (event: any) => {
    // Check if the form is valid
    if (event?.complete && event.complete === true && !event.error) {
      setCvcCompleted(true);
    } else {
      setCvcCompleted(false);
    }
  };

  return (
    <>
      <div className={styles.sign_up_form_container}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={(values: any) => {
            onFinish(
              values,
              stripe,
              elements ? elements.getElement(CardNumberElement) : null
            );
          }}
          autoComplete="off"
          className={styles.sign_up_form}
        >
          <Form.Item
            name="country"
            label={t("fields.selectCountry")}
            rules={[
              {
                required: requiredFields.country,
                message: t("fields.selectCountryRequired")
              }
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.country ? styles.required : ""}`
            }
          >
            <Select
              placeholder={t("fields.selectCountry")}
              options={countryOptions}
              value={selectedCountry}
              onChange={onChangeCountry}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
              style={{ textAlign: "left" }}
            />
          </Form.Item>
          {selectedCountry === "CA" ? (
            <Form.Item
              name="province"
              label={t("fields.selectProvince")}
              // initialValue={selectedCountry === "CA" ? companyData?.state : ""}
              rules={[
                {
                  required: selectedCountry === "CA" && requiredFields.province,
                  message: t("fields.selectProvinceRequired")
                }
              ]}
              className={
                `${styles.sign_up_form_item} ${requiredFields.province ? styles.required : ""}`
              }
            >
              <Select
                showSearch
                placeholder={t("fields.selectProvince")}
                options={provinceOptions}
                filterOption={selectFilterOption}
                className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
                style={{ textAlign: "left" }}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="state"
              label={t("fields.selectState")}
              // initialValue={selectedCountry === "US" ? companyData?.state : ""}
              rules={[
                {
                  required: selectedCountry === "US" && requiredFields.state,
                  message: t("fields.selectStateRequired")
                }
              ]}
              className={
                `${styles.sign_up_form_item} ${requiredFields.state ? styles.required : ""}`
              }
            >
              <Select
                showSearch
                placeholder={t("fields.selectState")}
                options={stateOptions}
                filterOption={selectFilterOption}
                className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
                style={{ textAlign: "left" }}
              />
            </Form.Item>
          )}
          <Form.Item
            name="city"
            label={t("fields.selectCity")}
            // initialValue={companyData?.city || ""}
            rules={[
              {
                required: requiredFields.city,
                message: t("fields.selectCityRequired")
              }
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.city ? styles.required : ""}`
            }
          >
            <Input
              type="text"
              placeholder={t("fields.selectCity")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>
          <Form.Item
            name="postalCode"
            label={selectedCountry === "CA"
              ? t("fields.postalCode")
              : t("fields.zipCode")}
            // initialValue={companyData?.postalCode || ""}
            rules={[
              {
                required: requiredFields.postalCode,
                message: selectedCountry === "CA"
                  ? t("fields.postalCodeRequired")
                  : t("fields.zipCodeRequired")
              },
              { validator: validatePostalCode },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.postalCode ? styles.required : ""}`
            }
          >
            <Input
              type="text"
              placeholder={t("fields.postalCode")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>
          <Form.Item
            name="cardNumber"
            label={t("fields.cardNumber")}
            rules={[
              {
                required: requiredFields.cardNumber,
                message: t("fields.cardNumberRequired")
              },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.cardNumber ? styles.required : ""}`
            }
          >
            <CardNumberElement
              onChange={onCardNumberChange}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_input_stripe}`}
            />
          </Form.Item>
          <Form.Item
            name="expirationDate"
            label={t("fields.expiryDate")}
            rules={[
              {
                required: requiredFields.expirationDate,
                message: t("fields.expiryDateRequired")
              },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.expirationDate ? styles.required : ""}`
            }
          >
            <CardExpiryElement
              onChange={onExpDateChange}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_input_stripe}`}
            />
          </Form.Item>
          <Form.Item
            name="cvc"
            label={t("fields.cvc")}
            rules={[
              {
                required: requiredFields.cvc,
                message: t("fields.cvcRequired")
              },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.cvc ? styles.required : ""}`
            }
          >
            <CardCvcElement
              onChange={onCvcChange}
              className={`${styles.sign_up_form_input} ${styles.sign_up_form_input_stripe}`}
            />
          </Form.Item>

          <Form.Item className={`${styles.sign_up_form_item} ${styles.sign_up_form_btn}`}>
            <Button htmlType="submit" className={styles.primary} disabled={disableButton}>
              {t("button.register")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default PaymentMethodForm;
