import { Button, Card, Col, Flex, Row, Space, Spin, Typography, notification } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPlanData } from "src/store/selectors/features/plans";
import RequestAppAction from "src/store/slices/appActions";
import { convertCurrency } from "src/utils/format";
import PageHeader from "src/components/header/PageHeader";
import "./plan.scss";
import InvoiceItem from "src/components/invoice";

const { Title, Text } = Typography;

const Plan: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [overageCost, setOverageCost]: any = useState("0.00");
  const [currency, setCurrency]: any = useState('CAD');
  const plansData: any = useSelector(getPlanData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setIsLoading(true);
    dispatch(
      RequestAppAction.fetchPlans({
        cbSuccess: () => {
          setIsLoading(false);
        },
        cbFailure: (e: string) => {
          setIsLoading(false);
          notification.error({ message: e });
        },
      })
    );
  }, []);

  useEffect(() => {
    console.log(plansData);
    if (plansData?.plan && plansData.overage) {
      const accountCurrency = plansData.plan.currency ?? 'CAD';
      let overageCost = 0;
      if (plansData.overage.currentCost && plansData.plan.messageRate) {
        overageCost = plansData.overage.currentCost * plansData.plan.messageRate;
      }

      if (accountCurrency !== 'CAD') {
        setCurrency(accountCurrency);
        convertCurrency(overageCost, 'CAD', 'USD').then(convertedAmount => {
          if (convertedAmount) {
            setOverageCost(convertedAmount);
          }
        });
      } else {
        setOverageCost(overageCost.toFixed(2));
      }
    }
  }, [plansData]);

  useEffect(() => {
    dispatch(RequestAppAction.fetchStripeProducts({
      cbSuccess: (res: any) => {
        console.log(res.data);
      },
      cbFailure: (e: string) => {
        notification.error({ message: e });
      },
    }));
  }, []);

  return (
    <div className="plan-page">
      <PageHeader pageTitle={t("plan.plan")} />
      <Spin spinning={isLoading}>
        <Card className="plan-card">
          <Flex
            vertical={true}
            justify="center"
            align="center"
            gap={30}
            className="plan-card-container"
          >
            <Space
              direction="vertical"
              className={"plan-card-header"}
            >
              <Text className="plan-card-top">
                {t("plan.aboutPlan")}
              </Text>
              <Text className="plan-card-title">
                {t("plan.monthlyUsageAndPlan")}
              </Text>
              <Text className="plan-card-subtitle">
                {t("plan.subHeading")}
              </Text>
              <Text className="plan-card-subtitle">
                {t("plan.planPeriod", {
                  startDate: dayjs(plansData?.planStartDate).format("DD/MM/YYYY"),
                  endDate: dayjs(plansData?.planEndDate).format("DD/MM/YYYY"),
                })}
              </Text>
            </Space>

            <Space
              direction="horizontal"
              size={30}
              className="plan-inner-card-container"
            >
              <Space direction="vertical" size={10} className="plan-inner-card first-card">
                <div className="plan-inner-card-row">
                  <Title level={4} style={{ marginBottom: "1rem" }}>
                    {t("plan.currentPlan")}: {plansData?.plan?.subscriptionPlan}
                  </Title>
                  {/* <Text className="font-size-14 font-weight-300">
                    {t("plan.dollarPerMonth", {
                      dollar: plansData?.plan?.monthlyRate || 0,
                    })}
                  </Text> */}
                  <Text className="plan-inner-card-text">
                    {t("plan.inboundSmsUnits")}<br /><span>{plansData?.plan?.inboundUnits || 0}</span>
                  </Text>
                  <Text className="plan-inner-card-text">
                    {t("plan.outboundSmsUnits")}<br /><span>{plansData?.plan?.outboundUnits || 0}</span>
                  </Text>
                  {/* <Text className="font-size-14 font-weight-300">
                    {t("plan.overageCostCentPerUnit", {
                      cost: plansData?.plan?.overageCost || 0,
                    })}
                  </Text> */}
                  <Text className="plan-inner-card-text">
                    {t("plan.overageCostCentPerUnit")}<br />
                    <span>
                      {plansData?.plan?.messageRate || 0}
                      {" "}
                      {t("plan.centPerUnit", { currency: currency ?? "CAD" })}
                    </span>
                  </Text>
                </div>
              </Space>

              <Space direction="vertical" size={10} className="plan-inner-card">
                <div className="plan-inner-card-row">
                  <div className="plan-icon-container">
                    <img
                      src={ require("../../assets/icons/text.png") }
                      className = 'pointer-events-none'
                      alt='text icon'
                    />
                  </div>
                  <Title level={4}>
                    {t("plan.usage")}
                  </Title>
                </div>
                <div className="plan-inner-card-row">
                  <Text className="plan-inner-card-text">
                    {t("plan.currentUsageIs", { date: dayjs().format("DD/MM/YYYY") })}
                    <br />
                    <span>
                      {t("plan.units", { units: plansData?.usage?.currentUnits || 0 })}
                    </span>
                  </Text>
                  <Text className="plan-inner-card-text">
                    <span>
                      {t("plan.smsUnits", {
                        limit: plansData?.usage?.currentUnits || 0,
                        units: plansData?.usage?.limit || 0,
                      })}
                    </span>
                  </Text>
                </div>
              </Space>

              <Space direction="vertical" size={10} className="plan-inner-card">
                <div className="plan-inner-card-row">
                  <div className="plan-icon-container">
                    <img
                      src={ require("../../assets/icons/contract.png") }
                      className = 'pointer-events-none'
                      alt='pen and paper icon'
                    />
                  </div>
                  <Title level={4}>
                    {t("plan.overage")}
                  </Title>
                </div>
                <div className="plan-inner-card-row">
                  <Text className="plan-inner-card-text">
                    {t("plan.currentOverageIs", { date: dayjs().format("DD/MM/YYYY") })}
                    <br />
                    <span>
                      ${overageCost ?? "0.00"}{" "}{currency ?? "CAD"}
                    </span>
                  </Text>
                  <Text className="plan-inner-card-text">
                    <span>
                      {t("plan.youAreSmsUnits", {
                        sms: plansData?.overage?.currentUnits || 0,
                      })} {t("plan.overYourPlan")}
                    </span>
                  </Text>
                </div>
              </Space>
            </Space>

            <Space className="plan-inner-card-container">
              <div className="plan-inner-card plan-inner-card-long">
                <div style={{ flex: 2/3, textAlign: "left" }}>
                  {plansData?.hasPaymentMethod ? (
                    <Text>{t("plan.hasPaymentMethod")}</Text>
                  ) : (
                    <Text>{t("plan.doesNotHavePaymentMethod")}</Text>
                  )}
                </div>
                <Button
                  className="primary"
                  style={{ flex: 1/3 }}
                  onClick={() => navigate("/plan/payment-method")}
                >
                  {t("button.managePaymentMethod")}
                </Button>
              </div>
            </Space>

            <Space className="plan-inner-card-container">
              <div
                style={{ flexDirection: "column" }}
                className="plan-inner-card plan-inner-card-long"
              >
                {plansData?.invoices
                  && plansData?.invoices.length > 0
                  && plansData.invoices.map((invoice: any, index: number) => (
                  <InvoiceItem
                    key={`invoice-${index}`}
                    invoiceData={invoice}
                    currency={currency ?? "CAD"}
                  />
                ))}
              </div>
            </Space>
          </Flex>
        </Card>
      </Spin>
    </div>
  );
};

export default Plan;
