import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchStripeProductDbFailure,
  fetchStripeProductDbSuccess
} from "../slices/features/stripeProductDb";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchStripeProductDbSaga(action: any) {
  const { payload } = action;
  const { id } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchStripeProductDb,
      baseUrl,
      id
    );

    yield put(fetchStripeProductDbSuccess({ ...response }));

    let productsData = {};
    if (response.data) {
      productsData = response.data;
    }

    payload?.cbSuccess && payload?.cbSuccess(productsData);
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(fetchStripeProductDbFailure({ statusCode, statusText }));
  }
}

export function* watchFetchStripeProductDb() {
  yield takeLatest(
    RequestTypes.FETCH_STRIPE_PRODUCT_DB_REQUEST,
    fetchStripeProductDbSaga
  );
}
