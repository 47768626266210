import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  stripeProductState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  postApiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const stripeProductSlice = createSlice({
  name: "stripeProduct",
  initialState: INITIAL_STATE,
  reducers: {
    fetchStripeProducts: (state) => {
      state.stripeProductState.isLoading = true;
    },
    fetchStripeProductsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeProductState: {
        ...state.stripeProductState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchStripeProductsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeProductState: {
        ...state.stripeProductState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchStripeProduct: (state) => {
      state.stripeProductState.isLoading = true;
    },
    fetchStripeProductSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeProductState: {
        ...state.stripeProductState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchStripeProductFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeProductState: {
        ...state.stripeProductState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearStripeProduct: (state) => ({
      ...state,
      stripeProductState: {
        ...state.stripeProductState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: null,
      },
    }),
  },
});

export const {
  fetchStripeProducts,
  fetchStripeProductsFailure,
  fetchStripeProductsSuccess,
  fetchStripeProduct,
  fetchStripeProductFailure,
  fetchStripeProductSuccess,
  clearStripeProduct,
} = stripeProductSlice.actions;
export const stripeProductReducer = stripeProductSlice.reducer;
