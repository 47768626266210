import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  updateCompanyFailure,
  updateCompanySuccess
} from "../slices/features/updateCompany";
import { RequestTypes } from "../types";
import { notification } from "antd";

const updateCompanyService = new AppService();

function* updateCompany(action: any) {
  const { payload } = action;
  const { data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      updateCompanyService.updateCompany,
      baseUrl,
      data
    );

    notification.success({ message: "Company updated successfully!" });

    payload?.cbSuccess && payload?.cbSuccess();

    yield put(updateCompanySuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const {
      data: {
        errors: { message },
      },
    } = error;


    notification.error({ message: Array.isArray(message) ? message[0] : message });

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(updateCompanyFailure({ statusCode, statusText }));
  }
}

export function* watchUpdateCompany() {
  yield takeLatest(RequestTypes.UPDATE_COMPANY, updateCompany);
}
