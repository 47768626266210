import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Form, Input, Typography } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import styles from "src/pages/subscribe/subscribe.module.scss";
import { PHONENUMBERREGEX } from "src/constants/common";

const { Text } = Typography;

interface ICreateNumberForm {
  form: any;
  i: number;
  j: number;
  isUserExpand: boolean;
  numDids: number;
  numberTypes: any;
  setNumberTypes: (numberType: any) => void;
}

const CreateNumberForm = ({
  form,
  i,
  j,
  isUserExpand = false,
  numDids,
  numberTypes,
  setNumberTypes
}: ICreateNumberForm) => {
  const { t } = useTranslation();

  const [isExpand, setIsExpand] = useState<boolean>(true);
  const [existingOrRequestingNumber, setExistingOrRequestingNumber] = useState<boolean>(true);
  const [numberType, setNumberType] = useState<boolean>(true);

  useEffect(() => {
    const addNumber = form.getFieldValue(`user_${i}_did_${j}_addNumber`);
    if (!addNumber || addNumber === "") {
      setExistingOrRequestingNumber(false);
    }

    if (numberTypes) {
      const type = numberTypes[`user_${i}_did_${j}_numberType`];
      if (!type || type === t("fields.localNumber")) {
        setNumberType(true);
      } else {
        setNumberType(false);
      }
    }
  }, [numDids]);

  const didRequiredFields = {
    addNumber: true,
    specialRequests: false,
  };

  const resetFields = () => {
    if (existingOrRequestingNumber) {
      form.resetFields([`user_${i}_did_${j}_addNumber`]);
    } else {
      setNumberType(true);
      const types = numberTypes;
      delete types[`user_${i}_did_${j}_numberType`];
      setNumberTypes(numberTypes);
      form.resetFields([`user_${i}_did_${j}_specialRequests`]);
    }
  };

  const validatePhoneNumber = () => {
    const number = form.getFieldValue(`user_${i}_did_${j}_addNumber`);
    const trimmedValue = number ? number.trim() : "";

    if (!trimmedValue || trimmedValue.length === 0) {
      return Promise.resolve();
    }

    if (!PHONENUMBERREGEX.test(trimmedValue)) {
      return Promise.reject(new Error(t("fields.addNumberRestriction")));
    }

    if (trimmedValue.length !== 10) {
      return Promise.reject(new Error(t("fields.addNumberLength")));
    }

    for (let k = 1; k <= numDids; k++) {
      if (k !== j) {
        const value = form.getFieldValue(`user_${i}_did_${k}_addNumber`);
        if (value === trimmedValue) {
          return Promise.reject(new Error(
            t("fields.addNumberAlreadyInUse", { number: k })
          ));
        }
      }
    }

    return Promise.resolve();
  };

  return (
    <div
      className={`${styles.sign_up_form_item} ${styles.sign_up_form} ${styles.sign_up_form_accordion_section} ${isUserExpand ? "" : styles.hidden}`}
    >
      <div
        style={{ position: "relative" }}
        className={styles.sign_up_form_item}
      >
        <Text className={styles.sign_up_form_section_title}>
          {t(
            "subscribeStep3.numberInfo",
            { userNumber: i, numNumber: j }
          )}
        </Text>
        <Button
          className={styles.sign_up_form_accordion_btn}
          icon={
            isExpand ? ( <UpOutlined /> ) : ( <DownOutlined /> )
          }
          onClick={() => setIsExpand(!isExpand)}
        >
        </Button>
      </div>
      <div className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}>
        <label>{t("fields.enablingExistingOrNewNumber")}</label>
        <div style={{ marginTop: "8px" }} className={styles.checkboxes}>
          <Checkbox
            checked={existingOrRequestingNumber}
            onChange={() => {
              resetFields();
              setExistingOrRequestingNumber(!existingOrRequestingNumber)
            }}
          >
            {t("fields.existingNumber")}
          </Checkbox>
          <Checkbox
            checked={!existingOrRequestingNumber}
            onChange={() => {
              resetFields();
              setExistingOrRequestingNumber(!existingOrRequestingNumber)
            }}
          >
            {t("fields.requestNewNumber")}
          </Checkbox>
        </div>
      </div>
      {existingOrRequestingNumber ? (
        <Form.Item
          name={`user_${i}_did_${j}_addNumber`}
          label={t("fields.addNumber")}
          className={
            `${styles.sign_up_form_item} ${didRequiredFields.addNumber ? styles.required : ""} ${isExpand ? "" : styles.hidden}`
          }
          rules={[
            {
              required: existingOrRequestingNumber && didRequiredFields.addNumber,
              message: t("fields.addNumberRequired")
            },
            { validator: () => validatePhoneNumber() },
          ]}
        >
          <Input
            placeholder={t("fields.addNumber")}
            className={styles.sign_up_form_input}
          />
        </Form.Item>
      ) : (
        <>
          <div className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}>
            <label>{t("fields.typeOfNumber")}</label>
            <div style={{ marginTop: "8px" }} className={styles.checkboxes}>
              <Checkbox
                checked={numberType}
                onChange={() => {
                  setNumberType(!numberType);
                  const types = numberTypes;
                  types[`user_${i}_did_${j}_numberType`] = !numberType
                    ? t("fields.localNumber")
                    : t("fields.tollFreeNumber");
                  setNumberTypes(types);
                }}
              >
                {t("fields.localNumber")}
              </Checkbox>
              <Checkbox
                checked={!numberType}
                onChange={() => {
                  setNumberType(!numberType);
                  const types = numberTypes;
                  types[`user_${i}_did_${j}_numberType`] = !numberType
                    ? t("fields.localNumber")
                    : t("fields.tollFreeNumber");
                  setNumberTypes(types);
                }}
              >
                {t("fields.tollFreeNumber")}
              </Checkbox>
            </div>
          </div>
          <Form.Item
            name={`user_${i}_did_${j}_specialRequests`}
            label={t("fields.specialRequests")}
            className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}
          >
            <Input
              placeholder={t("fields.specialRequests")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default CreateNumberForm;
