import { useTranslation } from "react-i18next";
import { Button, Flex, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import Header from "../Header";
import styles from "src/pages/subscribe/subscribe.module.scss";

interface IStep2 {
  email: string;
  sendOtp: (email?: string, func?: any) => void;
  onFinish: (value: any, form: any) => void;
}

const Step2 = ({ email, sendOtp, onFinish }: IStep2) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const requiredFields = {
    otp: true,
  };

  return (
    <Flex
      vertical
      gap={25}
      justify="center"
      align="center"
      style={{ width: "100%", height: "100%" }}
      className={styles.sign_up_container}
    >
      <Header step={2} subH1={`${t("subscribeStep2.subHeading1")} ${email}`} />
      <div className={styles.sign_up_form_container}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={(values: any) => onFinish(values, form)}
          autoComplete="off"
          className={styles.sign_up_form}
        >
          <Form.Item
            name="otp"
            label={t("fields.enterOtp")}
            rules={[
              {
                required: requiredFields.otp,
                message: t("fields.enterOtpRequired")
              },
            ]}
            className={
              `${styles.sign_up_form_item} ${requiredFields.otp ? styles.required : ""}`
            }
          >
            <Input
              placeholder={t("fields.enterOtp")}
              className={styles.sign_up_form_input}
            />
          </Form.Item>

          <Form.Item className={`${styles.sign_up_form_item} ${styles.sign_up_form_btn}`}>
            <Button htmlType="submit" className={styles.primary}>
              {t("button.verifyEmail")}
            </Button>
          </Form.Item>

          <div className={styles.sign_up_form_note}>
            <b>
              {t("subscribeStep2.cannotFindTheEmail")}
              {" "}
              <span
                className={styles.inline_btn}
                onClick={() => sendOtp(email)}
              >
                {t("subscribeStep2.button")}
              </span>
              {" "}
              {t("subscribeStep2.or")}
              {" "}
              <a
                href="https://textifynow.com/contact-us/"
                target="_blank"
                className={styles.inline_btn}
              >
                {t("subscribeStep2.contact")}
              </a>
            </b>
          </div>
        </Form>
      </div>
    </Flex>
  );
};

export default Step2;
