export enum ROUTES {
  LOGIN = "/login",
  REGISTER = "REGISTER",
  DASHBOARD = "DASHBOARD",
  CONVERSATIONS = "/conversations",
  CONTACTS = "/contacts",
  GROUPCONVERSATION = "/group-conversation",
  GROUPCONTACTS = "/group-contacts",
  PASSWORD = "/setting/password",
  PREFERENCES = "/setting/preferences",
  TEMPLATES = "/setting/templates",
  TIME_OF_DAY = "/setting/time-of-day",
  VACATION = "/setting/vacation",
  KEYWORDS = "/setting/keywords",
  PLAN = "/plan",
  PAYMENTMETHOD = "/payment-method",
  SUBSCRIBE = "/subscribe",
  // more routes will be added here
}
