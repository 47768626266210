import { Flex, notification, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IStripeProducts } from "src/constants/types";
import RequestAppAction from "src/store/slices/appActions";
import PlanDetail from "../PlanDetail";
import Header from "../Header";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface IStep1 {
  setSelectedPlan: (plan: any) => void;
  nextStep: () => void;
}

const Plans = ({ setSelectedPlan, nextStep }: IStep1) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [plans, setPlans] = useState<IStripeProducts[]>([]);
  const [isLoadingPlans, setIsLoadingPlans] = useState<boolean>(true);

  useEffect(() => {
    if (!isLoadingPlans) return;

    dispatch(RequestAppAction.fetchStripeProductsDb({
      cbSuccess: (res: any) => {
        const sortedPlans = res.slice().sort((a: any, b: any) => {
          if (a.tier === undefined && b.tier === undefined) return 0;
          if (a.tier === undefined) return 1;
          if (b.tier === undefined) return -1;
          return a.tier - b.tier;
        });
        if (sortedPlans && sortedPlans.length > 0) {
          setPlans(sortedPlans);
        } else {
          setPlans(res);
        }
        setIsLoadingPlans(false);
      },
      cbFailure: (e: string) => {
        notification.error({ message: e });
        setIsLoadingPlans(false);
      },
    }));
  }, []);

  const onSelectPlan = (plan: any) => {
    setSelectedPlan(plan);
    nextStep();
  };

  const tapMoreProduct = {
    stripeProductName: "Tap More",
    monthlyRate: "",
    messageRate: "",
    messageThreshold: 0,
  }

  return (
    <Flex gap={25} vertical className={styles.step0}>
      <Header step={0} hasLogo={false} breadcrumb={t("subscribeStep0.breadcrumb")} />
      <Text
        style={{ textAlign: "center" }}
        className="font-size-16 font-weight-500"
      >
        {t("subscribeStep0.selectPlan")}
      </Text>
      <Spin spinning={isLoadingPlans}>
        <Row gutter={[16, 24]} justify="center" style={{ width: "100%" }}>
          {plans && plans.map((product: any, index: number) => (
            <PlanDetail key={`plan-${index}`} planData={product} onSelectPlan={onSelectPlan} />
          ))}
          <PlanDetail
            planData={tapMoreProduct}
            onContact={() =>
              window.open('https://textifynow.com/contact-us/', '_blank')
            } />
        </Row>
        <div className={styles.notes}>
          <Text>
            <span dangerouslySetInnerHTML={{ __html: t("subscribeStep0.notes.note1") }} />
          </Text>
          <Text>
            <span dangerouslySetInnerHTML={{ __html: t("subscribeStep0.notes.note2") }} />
          </Text>
          <Text>
            <span dangerouslySetInnerHTML={{ __html: t("subscribeStep0.notes.note3") }} />
          </Text>
        </div>
      </Spin>
    </Flex>
  );
};

export default Plans;
