import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  createStripeSubscriptionFailure,
  createStripeSubscriptionSuccess
} from "../slices/features/stripeSubscription";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* createStripeSubscriptionSaga(action: any) {
  const { payload } = action;
  const { data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.createStripeSubscription,
      baseUrl,
      data
    );

    yield put(createStripeSubscriptionSuccess({ ...response }));

    let paymentMethodData = {};
    if (response.data) {
      paymentMethodData = response.data;
    }

    payload?.cbSuccess && payload?.cbSuccess(paymentMethodData);
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(createStripeSubscriptionFailure({ statusCode, statusText }));
  }
}

export function* watchCreateStripeSubscription() {
  yield takeLatest(
    RequestTypes.CREATE_STRIPE_SUBSCRIPTION_REQUEST,
    createStripeSubscriptionSaga
  );
}
