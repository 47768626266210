import { all } from "redux-saga/effects";
import { watchAddGroupContact } from "./addGroupContacts";
import { appSagas } from "./app";
import { watchLogin } from "./auth";
import { watchPostBulkScheduleMessage } from "./bulkScheduleMessage";
import { watchfetchConversationById } from "./conversationsById";
import { watchCreateGroup } from "./createGroup";
import { watchDeleteAutoresponderKeyword } from "./deleteAutoresponderKeyword";
import { watchDeleteBulkContact } from "./deleteBuilkContacts";
import { watchDeleteBulkConversation } from "./deleteBulkConversations";
import { watchBulkDeleteGroups } from "./deleteBulkGroup";
import { watchDeleteContact } from "./deleteContact";
import { watchDeleteConversation } from "./deleteConversation";
import { watchDeleteEvents } from "./deleteEvent";
import { watchfetchDeleteGroup } from "./deleteGroup";
import { watchDeleteGroupContact } from "./deleteGroupContact";
import { watchDeleteHolidayResponder } from "./deleteHolidayResponder";
import { watchDeleteNotes } from "./deleteNote";
import { watchDeleteScheduleMessage } from "./deleteScheduleaMessage";
import { watchDeleteTemplate } from "./deleteTemplate";
import { watchDeleteWorkingDays } from "./deleteWorkingDays";
import { watchDidNotification } from "./didNotificationSaga";
import { watchDownloadContacts } from "./downloadContacts";
import { watchDownlaodConversations } from "./downloadConversations";
import { watchDownlaodGroup } from "./downloadGroupList";
import { watchfetchAutoresponderKeyword } from "./fetchAutoresponderKeyword";
import { watchfetchBulkContacts } from "./fetchBulkConacts";
import { watchfetchContacts } from "./fetchContact";
import { watchfetchConversations } from "./fetchConversations";
import { watchfetchDid } from "./fetchDid";
import { watchfetchEvents } from "./fetchEvents";
import { watchfetchNotes } from "./fetchNotes";
import { watchFetchPreference } from "./fetchPreference";
import { watchFetchScheduleMessage } from "./fetchScheduleMessage";
import { watchFetchTemplate } from "./fetchTemplate";
import { watchfetchUser } from "./fetchUser";
import { watchForgetPassword } from "./forgetPassword";
import { watchGetAutoResponder } from "./getAutoresponder";
import { watchGetHolidayResponder } from "./getHolidayResponder";
import { watchfetchLoggedInUser } from "./getLoggedInUser";
import { watchGetWorkingDays } from "./getWorkingDays";
import { watchGroupContact } from "./groupContacts";
import { watchGroupMessages } from "./groupMessages";
import { watchGroups } from "./groups";
import { watchPostMessage } from "./message";
import { watchNewContact } from "./newContact";
import { watchfetchPlans } from "./plansSaga";
import { watchPostAutoresponderKeyword } from "./postAutoresponderKeyword";
import { watchPostEvents } from "./postEvents";
import { watchPostGroupMessages } from "./postGroupMessage";
import { watchPostHolidayResponder } from "./postHolidayResponder";
import { watchPostMultipleMessage } from "./postMultipleMessage";
import { watchPostNotes } from "./postNotes";
import { watchPostPreference } from "./postPreference";
import { watchPostScheduleMessage } from "./postScheduleMessage";
import { watchPostTemplate } from "./postTemplate";
import { watchPostWorkingDays } from "./postWorkingDays";
import { watchPutAutoResponder } from "./putAutoResponder";
import { watchPutAutoresponderKeyword } from "./putAutoresponderKeyword";
import { watchPutChangePassword } from "./putChangePassword";
import { watchPutEvents } from "./putEvents";
import { watchPutNotes } from "./putNotes";
import { watchPutScheduleMessage } from "./putScheduleMessage";
import { watchPutTemplate } from "./putTemplate";
import { watchPutUser } from "./putUser";
import { watchPutWorkingDays } from "./putWorkingDays";
import { watchResetPassword } from "./resetPassword";
import { watchPostSignout } from "./signout";
import { watchUpdateGroupName } from "./updateGroupName";
import { watchUpdateHolidayResponder } from "./updateHolidayResponder";
import { watchUploadContacts } from "./uploadContacts";
import { watchUploadFiles } from "./uploadFiles";
import { watchVerifyToken } from "./verifyToken";
import { watchFetchStripeCustomer } from "./stripeCustomerSaga";
import { watchUpdateStripeCustomerBillingAddress } from "./updateStripeCustomerBillingAddressSaga";
import { watchCreateStripeSubscription } from "./createStripeSubscriptionSaga";
import { watchFetchStripePaymentMethods } from "./fetchStripePaymentMethodsSaga";
import { watchFetchStripePaymentMethod } from "./fetchStripePaymentMethodSaga";
import { watchSetUpStripePaymentMethod } from "./setUpStripePaymentMethodSaga";
import { watchCreateStripePaymentMethod } from "./createStripePaymentMethodSaga";
import { watchUpdateStripePaymentMethod } from "./updateStripePaymentMethodSaga";
import { watchCreateStripeProductDb } from "./createStripeProductDbSaga";
import { watchFetchStripeProductsDb } from "./fetchStripeProductsDbSaga";
import { watchFetchStripeProductDb } from "./fetchStripeProductDbSaga";
import { watchFetchStripeProducts } from "./fetchStripeProductsSaga";
import { watchFetchStripeProduct } from "./fetchStripeProductSaga";
import { watchSubscribeNewUser } from "./subscribeNewUserSaga";
import { watchSubscribeExistingAccount } from "./subscribeExistingAccountSaga";
import { watchFetchCompanyStripeSubscriptions } from "./fetchCompanyStripeSubscriptionsSaga";
import { watchCreateCompany } from "./createCompanySaga";
import { watchUpdateCompany } from "./updateCompanySaga";
import { watchAvailabilityUsername } from "./availabilityUsername";
import { watchSendOtp } from "./sendOtp";
import { watchVerifyOtp } from "./verifyOtp";

export default function* rootSaga() {
  yield all([
    ...appSagas,
    watchLogin(),
    watchPostSignout(),
    watchfetchDid(),
    watchfetchConversations(),
    watchfetchConversationById(),
    watchfetchContacts(),
    watchfetchNotes(),
    watchPostNotes(),
    watchfetchUser(),
    watchPutNotes(),
    watchDeleteNotes(),
    watchPostScheduleMessage(),
    watchFetchScheduleMessage(),
    watchDeleteScheduleMessage(),
    watchPutScheduleMessage(),
    watchFetchTemplate(),
    watchPostTemplate(),
    watchPutTemplate(),
    watchDeleteTemplate(),
    watchFetchPreference(),
    watchPostPreference(),
    watchForgetPassword(),
    watchVerifyToken(),
    watchResetPassword(),
    watchPutChangePassword(),
    watchPostHolidayResponder(),
    watchGetHolidayResponder(),
    watchUpdateHolidayResponder(),
    watchDeleteHolidayResponder(),
    watchGetAutoResponder(),
    watchPutAutoResponder(),
    watchPostWorkingDays(),
    watchGetWorkingDays(),
    watchPutWorkingDays(),
    watchDeleteWorkingDays(),
    watchfetchAutoresponderKeyword(),
    watchPostAutoresponderKeyword(),
    watchPutAutoresponderKeyword(),
    watchDeleteAutoresponderKeyword(),
    watchPutUser(),
    watchPostMessage(),
    watchNewContact(),
    watchPostMultipleMessage(),
    watchUploadContacts(),
    watchDownloadContacts(),
    watchGroups(),
    watchGroupContact(),
    watchAddGroupContact(),
    watchGroupMessages(),
    watchPostGroupMessages(),
    watchCreateGroup(),
    watchUpdateGroupName(),
    watchDeleteGroupContact(),
    watchDeleteConversation(),
    watchDeleteContact(),
    watchfetchDeleteGroup(),
    watchDeleteBulkContact(),
    watchDeleteBulkConversation(),
    watchUploadFiles(),
    watchBulkDeleteGroups(),
    watchPostEvents(),
    watchPutEvents(),
    watchDeleteEvents(),
    watchfetchEvents(),
    watchDownlaodGroup(),
    watchDownlaodConversations(),
    watchfetchLoggedInUser(),
    watchPostBulkScheduleMessage(),
    watchfetchBulkContacts(),
    watchfetchPlans(),
    watchDidNotification(),
    watchFetchStripeCustomer(),
    watchUpdateStripeCustomerBillingAddress(),
    watchCreateStripeSubscription(),
    watchFetchCompanyStripeSubscriptions(),
    watchFetchStripePaymentMethods(),
    watchFetchStripePaymentMethod(),
    watchSetUpStripePaymentMethod(),
    watchCreateStripePaymentMethod(),
    watchUpdateStripePaymentMethod(),
    watchCreateStripeProductDb(),
    watchFetchStripeProductsDb(),
    watchFetchStripeProductDb(),
    watchFetchStripeProducts(),
    watchFetchStripeProduct(),
    watchSubscribeNewUser(),
    watchSubscribeExistingAccount(),
    watchCreateCompany(),
    watchUpdateCompany(),
    watchAvailabilityUsername(),
    watchSendOtp(),
    watchVerifyOtp(),
  ]);
}
