import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  subscribeNewUserFailure,
  subscribeNewUserSuccess
} from "../slices/features/stripeSubscription";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* subscribeNewUserSaga(action: any) {
  const { payload } = action;
  const { data } = payload;

  console.log(data);

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.subscribeNewUser,
      baseUrl,
      data
    );

    yield put(subscribeNewUserSuccess({ ...response }));

    let subscribeData = {};
    if (response.data) {
      subscribeData = response.data;
    }

    payload?.cbSuccess && payload?.cbSuccess(subscribeData);
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(subscribeNewUserFailure({ statusCode, statusText }));
  }
}

export function* watchSubscribeNewUser() {
  yield takeLatest(
    RequestTypes.SUBSCRIBE_NEW_USER_REQUEST,
    subscribeNewUserSaga
  );
}
