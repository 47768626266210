import { Card, Flex, Space, Spin, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeCardNumberElement } from "@stripe/stripe-js";
import { getLoginData } from "src/store/selectors/features/auth";
import RequestAppAction from "src/store/slices/appActions";
import PageHeader from "src/components/header/PageHeader";
import PaymentMethodForm from "src/components/payment-method";
import "../plan.scss";
import { formatAddress } from "src/utils/format";

const { Text } = Typography;

const PaymentMethod: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedUser = useSelector(getLoginData);

  const [isLoading, setIsLoading] = useState(true);
  const [paymentIntentSecret, setPaymentIntentSecret] = useState<string>("");
  const [companyData, setCompanyData] = useState<any>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
  const [stripePromise, setStripePromise] = useState(() => 
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY ?? "")
  )

  useEffect(() => {
    if (!companyData && selectedUser?.company) {
      setCompanyData(selectedUser.company);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser && paymentIntentSecret) {
      setIsLoading(false);
    }
  }, [selectedUser, paymentIntentSecret]);

  const setUpStripePaymentMethod = (
    billingDetails: any,
    stripe: Stripe,
    cardElement: StripeCardNumberElement
  ) => {
    stripe.confirmCardSetup(
      paymentIntentSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: billingDetails,
        }
      }
    ).then((result: any) => {
      if (result.error) {
        notification.error({ message: result.error });
      } else {
        dispatch(RequestAppAction.createStripePaymentMethod({
          id: companyData.id,
          paymentMethodId: result.setupIntent.payment_method,
          cbSuccess: () => {
            notification.success({ message: t("paymentMethod.success") });
            navigate("/plan");
          },
          cbFailure: (e: string) => {
            notification.error({ message: e });
            navigate("/plan");
          },
        }));
      }
    });
  };

  const onFinish = (
    values: any,
    stripe: Stripe | null,
    cardElement: StripeCardNumberElement | null
  ) => {
    if (!values) {
      return;
    }

    const address = formatAddress(
      values.country,
      values.province,
      values.state,
      values.city,
      values.postalCode
    );
    if (
      !address?.country || !address.state || !address.city || !address.postalCode
    ) {
      notification.error({ message: t("paymentMethod.addressRequired") });
      return;
    }

    if (
      !companyData || !stripe || !cardElement || !paymentIntentSecret
    ) {
      notification.error({ message: t("paymentMethod.error") });
      return;
    }

    dispatch(RequestAppAction.updateCompany({
      data: {
        id: companyData.id,
        ...address
      },
      cbSuccess: (_res: any) => {
        setUpStripePaymentMethod(
          {
            address: {
              country: address.country,
              state: address.state,
              city: address.city,
              line1: address.line1,
              line2: address.line2,
              postal_code: address.postalCode,
            },
            email: companyData.billingEmailAddress,
            name: companyData.name,
          },
          stripe,
          cardElement
        )
      },
      cbFailure: (e: string) => {
        notification.error({ message: e });
      },
    }));
  };

  return (
    <div className="plan-page">
      <PageHeader pageTitle={t("paymentMethod.paymentMethod")} />
      <Spin spinning={isLoading}>
        <Card className="plan-card">
          <Flex
            vertical={true}
            justify="center"
            align="center"
            gap={30}
            className="plan-card-container"
          >
            <Space
              direction="vertical"
              className={"plan-card-header"}
            >
              <Text className="plan-card-top">
                {t("paymentMethod.aboutPaymentmMthod")}
              </Text>
              <Text className="plan-card-title">
                {t("paymentMethod.paymentMethod")}
              </Text>
              <Text className="plan-card-subtitle">
                {t("paymentMethod.subHeading")}
              </Text>
            </Space>
            <Elements stripe={stripePromise}>
              <PaymentMethodForm
                companyData={companyData}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                setPaymentIntentSecret={setPaymentIntentSecret}
                onFinish={onFinish}
              />
            </Elements>
          </Flex>
        </Card>
      </Spin>
    </div>
  );
};

export default PaymentMethod;
