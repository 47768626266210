export const capitalizeFirstLetter = (str: string) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const getOptionsList = (options: any) => {
  const list = [];

  if (options && Object.keys(options).length > 0) {
    for (const key in options) {
      list.push({
        key,
        label: options[key as keyof any], value: key
      });
    }
  }

  return list;
};
