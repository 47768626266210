import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  availabilityState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: false,
  },
  apiStatusDid: {
    statusCode: null,
    statusText: null,
    data: false,
  },
};

export const availabilitySlice = createSlice({
  name: "otp",
  initialState: INITIAL_STATE,
  reducers: {
    sendOtp: (state) => {
      state.availabilityState.isLoading = true;
    },
    sendOtpSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      availabilityState: {
        ...state.availabilityState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    sendOtpFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      availabilityState: {
        ...state.availabilityState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    verifyOtp: (state) => {
      state.availabilityState.isLoading = true;
    },
    verifyOtpFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      availabilityState: {
        ...state.availabilityState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    verifyOtpSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      availabilityState: {
        ...state.availabilityState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  sendOtp,
  sendOtpFailure,
  sendOtpSuccess,
  verifyOtp,
  verifyOtpFailure,
  verifyOtpSuccess,
} = availabilitySlice.actions;
export const availabilityReducer = availabilitySlice.reducer;
