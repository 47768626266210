import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  stripeProductDbState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  postApiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const stripeProductDbSlice = createSlice({
  name: "stripeProductDb",
  initialState: INITIAL_STATE,
  reducers: {
    createStripeProductDb: (state) => {
      state.stripeProductDbState.isLoading = true;
    },
    createStripeProductDbSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeProductDbState: {
        ...state.stripeProductDbState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    createStripeProductDbFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeProductDbState: {
        ...state.stripeProductDbState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchStripeProductsDb: (state) => {
      state.stripeProductDbState.isLoading = true;
    },
    fetchStripeProductsDbSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeProductDbState: {
        ...state.stripeProductDbState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchStripeProductsDbFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeProductDbState: {
        ...state.stripeProductDbState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchStripeProductDb: (state) => {
      state.stripeProductDbState.isLoading = true;
    },
    fetchStripeProductDbSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeProductDbState: {
        ...state.stripeProductDbState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchStripeProductDbFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeProductDbState: {
        ...state.stripeProductDbState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearStripeProduct: (state) => ({
      ...state,
      stripeProductDbState: {
        ...state.stripeProductDbState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: null,
      },
    }),
  },
});

export const {
  createStripeProductDb,
  createStripeProductDbFailure,
  createStripeProductDbSuccess,
  fetchStripeProductsDb,
  fetchStripeProductsDbFailure,
  fetchStripeProductsDbSuccess,
  fetchStripeProductDb,
  fetchStripeProductDbFailure,
  fetchStripeProductDbSuccess,
  clearStripeProduct,
} = stripeProductDbSlice.actions;
export const stripeProductDbReducer = stripeProductDbSlice.reducer;
