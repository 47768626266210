import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchStripeProductFailure,
  fetchStripeProductSuccess
} from "../slices/features/stripeProduct";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchStripeProductSaga(action: any) {
  const { payload } = action;
  const { id } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchStripeProduct,
      baseUrl,
      id
    );

    yield put(fetchStripeProductSuccess({ ...response }));

    let productsData = {};
    if (response.data) {
      productsData = response.data;
    }

    payload?.cbSuccess && payload?.cbSuccess(productsData);
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(fetchStripeProductFailure({ statusCode, statusText }));
  }
}

export function* watchFetchStripeProduct() {
  yield takeLatest(
    RequestTypes.FETCH_STRIPE_PRODUCT_REQUEST,
    fetchStripeProductSaga
  );
}
