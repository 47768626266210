export enum SubscriptionUpdateType {
  UPGRADE = 'UPGRADE',
  DOWNGRADE = 'DOWNGRADE',
  CANCEL = 'CANCEL',
}

export const freePlanDetails = {
  description: "INBOUND only",
  features: [
    "Access to our <b>Mobile</b> and <b>Web</b> Apps",
    "<b>Unlimited</b> inbound texts",
    "No cost",
    "No credit card required",
    "Get a <b>new</b> number or <b>enable</b> your existing local, landline or VolP number",
    "Easily scale to one of our Premium Packages",
    "1 number (1 DID)",
    "1 user",
  ]
};

export const morePlanDetails = {
  description: "up to <b>10,000</b> monthly outbound texts",
  features: [
    "Get all the premium features and more",
  ]
};

export const getPlanDetails = (monthlyRate: string) => {
  if (monthlyRate === "") {
    return morePlanDetails;
  }

  let monthlyRateStr = monthlyRate.toString();
  monthlyRateStr = monthlyRateStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (monthlyRateStr === "0") {
    return freePlanDetails;
  }

  const planDetails = {
    description: `up to <b>${monthlyRateStr}</b> monthly outbound texts`,
    features: [
      "Access to our <b>Mobile</b> and <b>Web</b> Apps",
      "Get a new number or enable your existing local, landline, toll-free or VOIP number",
      `<b>${monthlyRateStr}</b> outbound text units`,
      "<b>Multiple</b> DIDs & User support",
      "Important date/appointment reminders",
      "Ability to schedule messages",
      "Set up Keyword Autoresponders",
      "Unlimited Contacts",
      "Import/export contacts",
      "Conversation backup via email or file export",
      "Search contacts and conversations",
      "Ability to schedule messages",
      "Set up Keyword Autoresponders",
      "Set up Automatic Responders",
      "Unlimited Contacts",
      "Import/export contacts",
      "Conversation backup via email or file export",
      "Search contacts and conversations",
      "Block unwanted incoming messages from certain contacts",
      "MMS support",
      "Mass messaging",
      "Ability to create templates",
    ],
  };
  return planDetails;
}
