import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Flex, Form, Row, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { convertCurrency, formatAddressToString, formatPhoneNumber } from "src/utils/format";
import moment from "moment-timezone";
import Header from "../Header";
import Step4 from "./Step4";
import Step6 from "./Step6";
import styles from "src/pages/subscribe/subscribe.module.scss";
import { labelStyle } from "src/constants/samples";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";

const { Text } = Typography;

interface IStep5 {
  prevStep: () => void,
  subscriptionStep: number,
  setSubscriptionStep: (subscriptionStep: number) => void;
  setPaymentIntentSecret: (paymentIntentSecret: string) => void;
  setStripe: (stripe: any) => void;
  setCardElement: (cardElement: any) => void;
  userData: any;
  selectedPlan: any;
  additional: any;
  disableButton: boolean;
  onStage4: () => void;
  onFinish: () => void;
}

const Step5 = ({
  prevStep,
  subscriptionStep,
  setSubscriptionStep,
  setPaymentIntentSecret,
  setStripe,
  setCardElement,
  userData,
  selectedPlan,
  additional,
  disableButton,
  onStage4,
  onFinish,
}: IStep5) => {
  const { t } = useTranslation();

  const [freeTrialEndDate, setFreeTrialEndDate] = useState<string>('');
  const [currency, setCurrency] = useState<string>('CAD');
  const [monthlyRate, setMonthlyRate] = useState<string>('0.00');
  const [additionalUsersRate, setAdditionalUsersRate] = useState<string>('0.00');
  const [additionalNumbersRate, setAdditionalNumbersRate] = useState<string>('0.00');
  const [total, setTotal] = useState<string>('0.00');

  useEffect(() => {
    const timezone = userData?.timeZone ?? 'America/Toronto';
    const today = moment().tz(timezone).format()
    const freeTrialEndDate = moment(today).add(14, 'days').tz(timezone).format('MM/DD/YYYY')
    setFreeTrialEndDate(freeTrialEndDate);

    const accountCurrency = userData?.currency ?? 'CAD';
    setCurrency(accountCurrency);

    const monthlyRateNum = parseFloat(selectedPlan?.monthlyRate ?? "0.00");
    const additionalUsersRateNum = 5 * (additional?.numUser ?? 0);
    const additionalNumbersRateNum = 5 * (additional?.numDid ?? 0);
    const totalNum = monthlyRateNum + additionalUsersRateNum + additionalNumbersRateNum;

    if (accountCurrency !== 'CAD') {
      setCurrency(accountCurrency);
      convertCurrency(monthlyRateNum, 'CAD', 'USD').then(convertedAmount => {
        if (convertedAmount) {
          setMonthlyRate(convertedAmount);
        }
      });
      convertCurrency(additionalUsersRateNum, 'CAD', 'USD').then(convertedAmount => {
        if (convertedAmount) {
          setAdditionalUsersRate(convertedAmount);
        }
      });
      convertCurrency(additionalNumbersRateNum, 'CAD', 'USD').then(convertedAmount => {
        if (convertedAmount) {
          setAdditionalNumbersRate(convertedAmount);
        }
      });
      convertCurrency(totalNum, 'CAD', 'USD').then(convertedAmount => {
        if (convertedAmount) {
          setTotal(convertedAmount);
        }
      });
    } else {
      setMonthlyRate(monthlyRateNum.toFixed(2));
      setAdditionalUsersRate(additionalUsersRateNum.toFixed(2));
      setAdditionalNumbersRate(additionalNumbersRateNum.toFixed(2));
      setTotal(totalNum.toFixed(2));
    }
  }, []);

  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones: allTimezones,
  });

  const reviewBlock = (label: string, data: string) => {
    return (
      <div className={styles.review_block}>
        <Text className={styles.review_block_label}>
          {label}
        </Text>
        <Text className={styles.review_block_data}>
          {data ?? ""}
        </Text>
      </div>
    );
  };

  const reviewBlockDids = (dids: any, i: number) => {
    const newDids: any = [];
    const existingDids: any = [];

    for (let did of dids) {
      if (did.did && did.did !== "") {
        existingDids.push(did);
      } else {
        newDids.push(did);
      }
    }

    return (
      <>
        {existingDids && existingDids.length > 0 && (
          <div className={styles.review_block}>
            <Text className={styles.review_block_label}>
              {t("subscribeStep5.user", { number: i + 1 })} - {t("subscribeStep5.phoneNumberExisting")}
            </Text>
              {existingDids.map((did: any, j: number) => (
                <Text
                  key={`user-${i + 1}-did-existing-${j + 1}`}
                  className={styles.review_block_data}
                >
                  {formatPhoneNumber(did.did)}
                </Text>
              ))}
          </div>
        )}
        {newDids && newDids.length > 0 && (
          <div className={styles.review_block}>
            <Text className={styles.review_block_label}>
              {t("subscribeStep5.user", { number: i + 1 })} - {t("subscribeStep5.phoneNumberNew")}
            </Text>
            <Text className={styles.review_block_data}>
              {newDids.length}
              {" "}
              {newDids.length > 1 ? t("subscribeStep5.numbers") : t("subscribeStep5.number")}
            </Text>
          </div>
        )}
      </>
    );
  };

  const reviewSummaryLine = (
    label: string,
    data: number | string,
    labelSpan?: string,
  ) => (
    <div className={styles.review_summary_line}>
      <Text className={styles.review_summary_label}>
        {label}{labelSpan && <span>{labelSpan}</span>}
      </Text>
      <Text className={styles.review_summary_data}>
        {currency} ${data}
      </Text>
    </div>
  );

  return (
    <Flex
      vertical
      gap={25}
      justify="center"
      align="center"
      style={{ width: "100%", height: "100%" }}
      className={`${styles.sign_up_container} ${styles.summary_container}`}
    >
      <div
        style={{
          position: `${subscriptionStep === 4 ? "unset" : "fixed"}`,
          visibility: `${subscriptionStep === 4 ? "visible" : "hidden"}`,
          maxWidth: "768px",
          width: "100%",
        }}
        className={subscriptionStep === 4 ? "" : styles.hidden}
      >
        <Step4
          prevStep={prevStep}
          setPaymentIntentSecret={setPaymentIntentSecret}
          setStripe={setStripe}
          setCardElement={setCardElement}
          onFinish={onStage4}
        />
      </div>
      {subscriptionStep === 5 ? (
        <>
          <Header
            step={5}
            hasLogo={false}
            breadcrumb={t("subscribeStep5.breadcrumb")}
            leftAlign={true}
          />
          <div className={styles.review_container}>
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={() => onFinish()}
              autoComplete="off"
              className={styles.review}
            >
              <Row
                gutter={[16, 24]}
                justify="start"
                className={`${styles.review_row} ${styles.review_free}`}
              >
                <Text style={{
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "30px",
                }}>
                  {t("subscribeStep5.firstFree")}
                  {" "}
                  <br className={styles.review_free_newline} />
                  <span style={{ color: "rgb(42, 76, 156)" }}>
                    {t("subscribeStep5.yourBillingStartDate")}
                  </span>
                  {" "}
                  <span style={{ fontWeight: 400, color: "#475467" }}>
                    {freeTrialEndDate}
                  </span>
                </Text>
              </Row>
              <Row
                gutter={[16, 24]}
                justify="center"
                style={{ width: "100%" }}
                className={styles.review_row}
              >
                <Col
                  style={{
                    flex: 0.66,
                    display: "flex",
                    flexDirection: "row",
                  }}
                  className={styles.review_col}
                >
                  <Col
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className={styles.review_col_col}
                  >
                    <div className={styles.review_section}>
                      <EditOutlined
                        onClick={() => setSubscriptionStep(3)}
                        className={styles.review_section_edit}
                      />
                      <Text className={styles.review_section_title}>
                        {t("subscribeStep5.organizationInfo")}
                      </Text>
                      {reviewBlock(t("fields.companyName"), userData.name)}
                      {reviewBlock(t("fields.billingEmailAddress"), userData.billingEmailAddress)}
                      {reviewBlock(
                        t("subscribeStep5.timezone"),
                        parseTimezone(userData.timeZone)
                          ? parseTimezone(userData.timeZone).label
                          : userData.timeZone
                      )}
                      {reviewBlock(
                        t("subscribeStep5.location"),
                        formatAddressToString(
                          userData.postalCode,
                          userData.country,
                          userData.state,
                          userData.city,
                        )
                      )}
                      {reviewBlock(t("fields.howDidYouHear"), userData.notes)}
                    </div>
                    {/* <div className={styles.review_section}>
                      <Text className={styles.review_section_title}>
                        {t("subscribeStep5.billingInfo")}
                      </Text>
                    </div> */}
                  </Col>
                  <Col
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className={styles.review_col_col}
                  >
                    <div className={styles.review_section}>
                      <EditOutlined
                        onClick={() => setSubscriptionStep(3)}
                        className={styles.review_section_edit}
                      />
                      <Text className={styles.review_section_title}>
                        {t("subscribeStep5.userInfo")}
                      </Text>
                      {userData?.users
                        && userData.users.length > 0
                        && userData.users.map((user: any, i: number) => (
                          <React.Fragment key={`user-${i + 1}`}>
                            {reviewBlock(
                              `User #${i + 1} - ${t("fields.username")}`,
                              user.username
                            )}
                            {reviewBlock(
                              `User #${i + 1} - ${t("subscribeStep5.contactName")}`,
                              user.name
                            )}
                            {reviewBlock(
                              `User #${i + 1} - ${t("fields.emailAddress")}`,
                              user.email
                            )}
                            {user.dids
                              && user.dids.length > 0
                              && reviewBlockDids(user.dids, i)
                            }
                          </React.Fragment>
                        ))}
                    </div>
                  </Col>
                </Col>
                <Col
                  style={{
                    flex: 0.33,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className={styles.review_col}
                >
                  <div className={styles.review_section}>
                    <Text className={styles.review_section_title}>
                      {t("subscribeStep5.yourPackageSummary")}
                    </Text>
                    <div className={styles.review_summary}>
                      {reviewSummaryLine(
                        t("subscribeStep5.selectedPackage") + ": ",
                        monthlyRate,
                        selectedPlan?.name ?? ""
                      )}
                      {selectedPlan?.description && (
                        <div className={styles.review_summary_line}>
                          <Text
                            style={{ fontSize: "14px", lineHeight: "21px" }}
                            className={styles.review_summary_label}
                          >
                            <span>
                              {t("subscribeStep0.forBusinesses")}
                              {" "}
                              <span dangerouslySetInnerHTML={{ __html: selectedPlan.description }} />
                            </span>
                          </Text>
                        </div>
                      )}
                      {additional.numUser > 0 && (
                        reviewSummaryLine(
                          t("subscribeStep5.additionalUsers") + " x " + additional.numUser,
                          additionalUsersRate
                        )
                      )}
                      {additional.numDid > 0 && (
                        reviewSummaryLine(
                          t("subscribeStep5.additionalNumbers") + " x " + additional.numDid,
                          additionalNumbersRate
                        )
                      )}
                    </div>
                    <div className={`${styles.review_summary} ${styles.review_total}`}>
                      {reviewSummaryLine(
                        t("subscribeStep5.monthlyTotal"),
                        total
                      )}
                    </div>
                  </div>
                  <Form.Item className={`${styles.review_item} ${styles.review_btn}`}>
                    <Button htmlType="submit" className={styles.primary} disabled={disableButton}>
                      {t("button.subscribe")}
                    </Button>
                  </Form.Item>
                  <Form.Item className={`${styles.review_item} ${styles.review_btn}`}>
                    <Button
                      style={{
                        marginTop: "12px",
                        border: "1px solid #FAEBD5"
                      }}
                      className={styles.secondary}
                      onClick={prevStep}
                    >
                      {t("button.back")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>

          <div className={`${styles.sign_up_form_note} ${styles.small}`}>
            <Text>{t("subscribeStep5.note")}</Text>
          </div>
        </>
      ) : subscriptionStep === 6 ? (
        <Step6 />
      ) : <></>}
    </Flex>
  );
};

export default Step5;
