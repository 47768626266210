import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchStripeProductsFailure,
  fetchStripeProductsSuccess
} from "../slices/features/stripeProduct";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchStripeProductsSaga(action: any) {
  const { payload } = action;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchStripeProducts,
      baseUrl,
    );

    yield put(fetchStripeProductsSuccess({ ...response }));

    let productsData = [];
    if (response.data) {
      productsData = response.data;
    }

    payload?.cbSuccess && payload?.cbSuccess(productsData);
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(fetchStripeProductsFailure({ statusCode, statusText }));
  }
}

export function* watchFetchStripeProducts() {
  yield takeLatest(
    RequestTypes.FETCH_STRIPE_PRODUCTS_REQUEST,
    fetchStripeProductsSaga
  );
}
