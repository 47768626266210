import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  subscribeExistingAccountState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  postApiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const subscribeExistingAccountSlice = createSlice({
  name: "subscribeExistingAccount",
  initialState: INITIAL_STATE,
  reducers: {
    subscribeExistingAccount: (state) => {
      state.subscribeExistingAccountState.isLoading = true;
    },
    subscribeExistingAccountSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.subscribeExistingAccountState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    subscribeExistingAccountFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.subscribeExistingAccountState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearStripeSubscription: (state) => ({
      ...state,
      stripeSubscriptionState: {
        ...state.subscribeExistingAccountState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: null,
      },
    }),
  },
});

export const {
  subscribeExistingAccount,
  subscribeExistingAccountFailure,
  subscribeExistingAccountSuccess,
  clearStripeSubscription,
} = subscribeExistingAccountSlice.actions;
export const subscribeExistingAccountReducer = subscribeExistingAccountSlice.reducer;
