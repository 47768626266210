import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  stripePaymentMethodState: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
  postApiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
  },
};

export const stripePaymentMethodSlice = createSlice({
  name: "stripePaymentMethod",
  initialState: INITIAL_STATE,
  reducers: {
    fetchStripePaymentMethods: (state) => {
      state.stripePaymentMethodState.isLoading = true;
    },
    fetchStripePaymentMethodsSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchStripePaymentMethodsFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    fetchStripePaymentMethod: (state) => {
      state.stripePaymentMethodState.isLoading = true;
    },
    fetchStripePaymentMethodSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    fetchStripePaymentMethodFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    setUpStripePaymentMethod: (state) => {
      state.stripePaymentMethodState.isLoading = true;
    },
    setUpStripePaymentMethodSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    setUpStripePaymentMethodFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    createStripePaymentMethod: (state) => {
      state.stripePaymentMethodState.isLoading = true;
    },
    createStripePaymentMethodSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    createStripePaymentMethodFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    updateStripePaymentMethod: (state) => {
      state.stripePaymentMethodState.isLoading = true;
    },
    updateStripePaymentMethodSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
      },
    }),
    updateStripePaymentMethodFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
    clearStripePaymentMethod: (state) => ({
      ...state,
      stripePaymentMethodState: {
        ...state.stripePaymentMethodState,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: null,
      },
    }),
  },
});

export const {
  fetchStripePaymentMethods,
  fetchStripePaymentMethodsFailure,
  fetchStripePaymentMethodsSuccess,
  fetchStripePaymentMethod,
  fetchStripePaymentMethodFailure,
  fetchStripePaymentMethodSuccess,
  setUpStripePaymentMethod,
  setUpStripePaymentMethodFailure,
  setUpStripePaymentMethodSuccess,
  createStripePaymentMethod,
  createStripePaymentMethodFailure,
  createStripePaymentMethodSuccess,
  updateStripePaymentMethod,
  updateStripePaymentMethodSuccess,
  updateStripePaymentMethodFailure,
  clearStripePaymentMethod,
} = stripePaymentMethodSlice.actions;
export const stripePaymentMethodReducer = stripePaymentMethodSlice.reducer;
