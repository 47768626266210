import { Route, Routes } from "react-router-dom";
import Contacts from "src/pages/contacts";
import Converstion from "src/pages/conversations";
import Plan from "src/pages/plan";
import PaymentMethod from "src/pages/plan/payment-method";
import KeyWords from "src/pages/settings/autoresponder/keywords";
import TimeOfDay from "src/pages/settings/autoresponder/time-of-day";
import Vacation from "src/pages/settings/autoresponder/vacation";
import Password from "src/pages/settings/password";
import Preferences from "src/pages/settings/preferences";
import Templates from "src/pages/settings/templates";

const DashboardRoutes = () => (
  <Routes>
    <Route path="/*" element={<Converstion />}></Route>
    <Route path="/conversations" element={<Converstion />}></Route>
    <Route path="/contacts" element={<Contacts />}></Route>
    {/* <Route path="/group-contacts" element={<GroupContacts />}></Route>
    <Route path="/group-conversation" element={<GroupConverstion />}></Route> */}
    <Route path="/setting/password" element={<Password />}></Route>
    <Route path="/setting/preferences" element={<Preferences />}></Route>
    <Route path="/setting/templates" element={<Templates />}></Route>
    <Route path="/setting/time-of-day" element={<TimeOfDay />}></Route>
    <Route path="/setting/vacation" element={<Vacation />}></Route>
    <Route path="/setting/keywords" element={<KeyWords />}></Route>
    <Route path="/plan" element={<Plan />}></Route>
    <Route path="/plan/payment-method" element={<PaymentMethod />}></Route>
  </Routes>
);

export default DashboardRoutes;
