export const baseUrl = process.env.REACT_APP_BASE_URL;

export const Limit = `10`;

const ENDPOINTS = {
  LOGIN: `user/signin`,
  UPDATEPASSWORD: `user/password/update`,
  USERSETTINGS: "user/settings",
  USERDETAILS: "user",
  SIGNOUT: "user/signout",
  FETCHDIDS: "user/dids",
  FETCHCONVERSATIONS: (id: string, queryString: string) =>
    `user/dids/${id}/messages?${queryString}`,
  FETCHCONVERSATIONSBYID: (id: string, queryString: string) =>
    `user/contacts/${id}/messages?${queryString}`,
  FETCHCONTACTS: (id: string, queryString: string) =>
    `user/dids/${id}/contacts?${queryString}`,
  FETCHNOTES: (id: string, queryString: string) =>
    `user/contacts/${id}/notes?${queryString}`,
  POSTNOTES: (id: string) => `user/contacts/${id}/notes`,
  FETCHUSER: (id: string) => `user/contacts/${id}`,
  EDITNOTE: (id: string) => `user/notes/${id}`,
  FETCHSCHEDULEMESSAGE: (id: string, queryString: string) =>
    `user/contacts/${id}/scheduled-messages?${queryString}`,
  POSTSCHEDULEMESSAGE: (id: string) => `user/contacts/${id}/scheduled-messages`,
  EDITSCHEDULEMESSAGE: (id: string) => `user/scheduled-messages/${id}`,
  FETCHTEMPLATE: (id: string, queryString: string) =>
    `user/dids/${id}/templates?${queryString}`,
  POSTTEMPLATE: (id: string, queryString: string) =>
    `user/dids/${id}/templates${queryString ? `?${queryString}` : ""}`,
  EDITTEMPLATE: (id: string) => `user/templates/${id}`,
  FETCHPREFERENCE: "user/settings",
  FORGETPASSWORD: "user/forgot-password",
  VERIFYTOKEN: (id: string) => `user/verify-token/${id}`,
  RESETPASSWORD: (id: string) => `user/reset-password/${id}`,
  HOLIDAYRESPONDER: (id: string | number) => `user/dids/${id}/holidays`,
  UPDATEHOLIDAYRESPONDER: (id: string | number) => `user/holidays/${id}`,
  WORKINGDAYS: (id: string | number) => `user/dids/${id}/working-days`,
  PUTWORKINGDAYS: (id: string | number) => `user/working-days/${id}`,
  AUTORESPONDER: (id: string | number) => `user/dids/${id}/auto-responder`,
  POSTAUTORESPONDERKEYWORD: (id: string | number) => `user/dids/${id}/keywords`,
  AUTORESPONDERKEYWORD: (id: string | number) => `user/keywords/${id}`,
  SENDMESSAGE: (id: string, did: string) =>
    `user/contacts/${id}/messages?didId=${did}`,
  NEWCONTACT: (id: string) => `user/dids/${id}/contacts`,
  POSTMULTIPLEMESSAGE: (queryString: string) =>
    `user/contacts/bulk-messages?${queryString}`,
  UPLOADCONTACT: (id: string) => `user/dids/${id}/contacts/import`,
  EXPORTCONTACT: (id: number) => `user/dids/${id}/contacts/export`,
  GROUPLIST: (id: string, queryString: string) =>
    `user/dids/${id}/groups?${queryString}`,
  GROUPCONTACTLIST: (id: string, groupId: string, queryString: string) =>
    `user/dids/${id}/groups/${groupId}/contacts?${queryString}`,
  ADDGROUPCONTACT: (id: string, groupId: string) =>
    `user/dids/${id}/groups/${groupId}/contacts`,
  GROUPMESSAGES: (id: string, groupId: string, meta: string) =>
    `user/dids/${id}/groups/${groupId}/messages?${meta}`,
  POSTGROUPMESSAGE: (id: string, groupId: string) =>
    `user/dids/${id}/groups/${groupId}/messages`,
  CREATEGROUP: (id: string) => `user/dids/${id}/groups`,
  UPDATEGROUPNAME: (id: string, groupId: string) =>
    `user/dids/${id}/groups/${groupId}`,
  DELETEGROUPCONTACT: (id: string, groupId: string, contactId: string) =>
    `user/dids/${id}/groups/${groupId}/contacts/${contactId}`,
  DELETECONTACT: (id: string, queryString: string) =>
    `user/contacts/${id}${queryString ? `?${queryString}` : ""}`,
  DELETECONVERSATION: (id: string, queryString: string) =>
    `user/contacts/${id}/messages${queryString ? `?${queryString}` : ""}`,
  DELETEGROUP: (id: string, groupId: string, queryString: string) =>
    `user/dids/${id}/groups/${groupId}${queryString ? `?${queryString}` : ""}`,
  DELETEBULKCONTACT: (queryString?: string) =>
    `user/bulk/contacts${queryString ? `?${queryString}` : ""}`,
  DELETEBULKCONVERSATION: (queryString?: string) =>
    `user/bulk-messages/contacts${queryString ? `?${queryString}` : ""}`,
  UPLOADFILES: "user/upload-files",
  BULKDELETEGROUP: (id: string, queryString: string) =>
    `user/dids/${id}/bulk/groups${queryString ? `?${queryString}` : ""}`,
  FETCHEVENTS: (id: string, queryString: string) =>
    `user/contacts/${id}/events?${queryString}`,
  POSTEVENTS: (id: string) => `user/contacts/${id}/events`,
  PUTEVENTS: (id: string) => `user/events/${id}`,
  DOWNLOADGROUPMESSAGES: (did: string, groupId: string) =>
    `user/dids/${did}/groups/${groupId}/messages/export`,
  DOWNLOADCONVERSATION: (id: string) => `user/contacts/${id}/messages/export`,
  BULKSCHEDULEMESSAGE: (id: string) =>
    `user/contacts/bulk-scheduled-messages?didId=${id}`,
  PLANS: `user/plan`,
  SAMPLECSV: `https://textify-prod-assets.s3.ca-central-1.amazonaws.com/contacts-import-sample.csv`,
  FETCHSTRIPECUSTOMER: (id: string) => `stripe-customers/${id}`,
  UPDATESTRIPECUSTOMERBILLINGADDRESS: (id: string) => `stripe-customers/${id}/update-billing-address`,
  CREATESTRIPESUBSCRIPTION: `stripe-subscriptions`,
  SUBSCRIBENEWUSER: `stripe-subscriptions/subscribe-new`,
  SUBSCRIBEEXISTINGACCOUNT: `company/subscribe`,
  FETCHCOMPANYSUBSCRIPTIONS: (id: string) => `stripe-subscriptions/company/${id}`,
  STRIPEPAYMENTMETHODSETUP: `stripe-customers/payment-method`,
  STRIPEPAYMENTMETHODS: (id: string) => `stripe-customers/${id}/payment-method`,
  STRIPEPAYMENTMETHOD: (id: string, paymentMethodId: string) =>
    `stripe-customers/${id}/payment-method/${paymentMethodId}`,
  FETCHACTIVESTRIPEPRODUCTS: `stripe-products/active-packages`,
  STRIPEPRODUCTSDB: `stripe-products`,
  STRIPEPRODUCTDB: (id: string) => `stripe-products/stripe/${id}`,
  STRIPEPRODUCTS: `stripe-products/stripe`,
  STRIPEPRODUCT: (id: string) => `stripe-products/stripe/${id}`,
  COMPANY: `company`,
  ONECOMPANY: (id: string) => `company/${id}`,
  COMPANYADDRESS: (id: string) => `company/address/${id}`,
  ROLES: `roles`,
  USERNAMEAVAILABLE: (username: string) => `user/username-availability/${username}`,
  SENDOTP: `user/send-otp`,
  VERIFYOTP: `user/verify-otp`,
};

export default ENDPOINTS;
