import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import { RequestTypes } from "../types";
import { sendOtpFailure, sendOtpSuccess } from "../slices/features/otp";

const appService = new AppService();

function* sendOtpSaga(action: any) {
  const { payload } = action;
  const { data } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.sendOtp,
      baseUrl,
      data
    );

    payload?.cbSuccess && payload?.cbSuccess({ ...response });

    yield put(sendOtpSuccess({ ...response }));
  } catch (errors: any) {
    const { statusCode, statusText } = errors;
    const message = errors?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(sendOtpFailure({ statusCode, statusText }));
  }
}

export function* watchSendOtp() {
  yield takeLatest(
    RequestTypes.POST_SEND_OTP,
    sendOtpSaga
  );
}
