import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchStripePaymentMethodFailure,
  fetchStripePaymentMethodsSuccess
} from "../slices/features/stripePaymentMethod";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchStripePaymentMethodsSaga(action: any) {
  const { payload } = action;
  const { id } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchStripePaymentMethods,
      baseUrl,
      id
    );

    yield put(fetchStripePaymentMethodsSuccess({ ...response }));

    let paymentMethodData = [];
    if (response.data) {
      paymentMethodData = response.data;
    }

    payload?.cbSuccess && payload?.cbSuccess(paymentMethodData);
  } catch (error: any) {
    const { statusCode, statusText } = error;
    yield put(fetchStripePaymentMethodFailure({ statusCode, statusText }));
  }
}

export function* watchFetchStripePaymentMethods() {
  yield takeLatest(
    RequestTypes.FETCH_STRIPE_PAYMENT_METHODS_REQUEST,
    fetchStripePaymentMethodsSaga
  );
}
