import { useTranslation } from "react-i18next";
import { Flex, Typography } from "antd";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface IHeaderProps {
  step: number;
  hasLogo?: boolean;
  breadcrumb?: string;
  subH1?: string;
  subH2?: string;
  leftAlign?: boolean;
}

const Header = ({
  step,
  hasLogo = true,
  breadcrumb = "",
  subH1 = "",
  subH2 = "",
  leftAlign = false,
}: IHeaderProps) => {
  const { t } = useTranslation();

  const heading = t(`subscribeStep${step}.heading`);
  const subHeading = t(`subscribeStep${step}.subHeading`);
  const subHeading1 = subH1 !== "" ? subH1 : t(`subscribeStep${step}.subHeading1`);
  const subHeading2 = subH2 !== "" ? subH2 : t(`subscribeStep${step}.subHeading2`);

  return (
    <Flex
      vertical
      justify="center"
      align="center"
      style={{
        width: "100%",
        height: "auto",
      }}
      className={`${styles.header} ${leftAlign ? styles.leftAlign : ""}`}
    >
      {hasLogo && (
        <div>
          <img
            alt="Textify Logo"
            style={{ marginBottom: "30px", height: "65px" }}
            src={require("../../assets/images/logo/logo.png")}
          />
        </div>
      )}
      {breadcrumb && (
        <Text
          style={{ marginBottom: "12px", textAlign: "center", color: "#2A4C9C" }}
          className="font-size-16 font-weight-500"
        >
          {breadcrumb}
        </Text>
      )}
      {heading && (
        <Text
          style={{ marginBottom: "0" }}
          className="font-size-26 font-weight-600"
        >
          {heading}
        </Text>
      )}
      {subHeading && (
        <Text
          style={{ marginTop: "20px", color: "#636467" }}
          className="font-size-20 font-weight-400"
        >
          <span
            dangerouslySetInnerHTML={{ __html: subHeading }}
          />
        </Text>
      )}
      {subHeading1 && (
        <Text
          style={{ marginTop: "20px", color: "#636467" }}
          className="font-size-20 font-weight-400"
        >
          <span
            dangerouslySetInnerHTML={{ __html: subHeading1 }}
          />
        </Text>
      )}
      {subHeading2 && (
        <Text
          style={{ marginTop: "12px", color: "#636467" }}
          className="font-size-20 font-weight-400"
        >
          <span
            dangerouslySetInnerHTML={{ __html: subHeading2 }}
          />
        </Text>
      )}
    </Flex>
  );
};
export default Header;
