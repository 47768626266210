import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  createCompanyStates: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
  },
  createCompanyData: null,
};

export const createCompanySlice = createSlice({
  name: "createCompany",
  initialState: INITIAL_STATE,
  reducers: {
    initiateCreateCompany: (state, { payload }) => {
      state.createCompanyStates.isLoading = true;
      console.log(payload);
      // payload;
    },
    createCompanySuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      createCompanyStates: {
        ...state.createCompanyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
      createCompanyData: action.payload.data,
    }),
    createCompanyFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      createCompanyStates: {
        ...state.createCompanyStates,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
      },
    }),
  },
});

export const {
  initiateCreateCompany,
  createCompanySuccess,
  createCompanyFailure,
} = createCompanySlice.actions;
export const createCompanyReducer = createCompanySlice.reducer;
