import axios from "axios";

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return "";

  let num = phoneNumber.trim().replace(/[.\- )(]/g, "");
  if (!num.startsWith("+1")) {
    num = "+1" + num;
  }
  return `+1 (${num.slice(2, 5)}) ${num.slice(5, 8)}-${num.slice(8)}`;
};

export const convertCurrency = async (amount: number, baseCurrency: string, targetCurrency: string) => {
  const fromCurrency = baseCurrency.toLowerCase();
  const toCurrency = targetCurrency.toLowerCase();

  try {
    // Free Currency Exchange Rates API: https://github.com/fawazahmed0/exchange-api
    const response = await axios.get(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${fromCurrency}.json`, {
      params: {
        base: baseCurrency
      }
    });
    const rate = response.data[fromCurrency][toCurrency];
    return (amount * rate).toFixed(2);
  } catch (error) {
    console.error('Error during currency conversion:', error);
    return null;
  }
};

export const formatAddress = (
  postalCode: string,
  country: string,
  province?: string,
  state?: string,
  city?: string,
  line1?: string,
  line2?: string,
) => {
  // const formattedState =
  //   country === 'CA' && province
  //     ? province
  //     : country === 'US' && state
  //     ? state
  //     : null;
  // if (!formattedState) {
  //   return null;
  // }
  const address = {
    country: country ?? '',
    state: province ? province : state ? state : '',
    city: city ?? '',
    line1: line1 ?? '',
    line2: line2 ?? '',
    postalCode: postalCode,
  };
  return address;
}

export const formatAddressToString = (
  postalCode: string,
  country: string,
  state?: string,
  city?: string,
  line1?: string,
  line2?: string,
): string => {
  const addressParts = [
    line2,
    line1,
    city,
    state,
    country,
    postalCode
  ].filter(part => part && part.trim() !== "");

  return addressParts.join(", ");
}
